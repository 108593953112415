import { defineCustomElement } from 'vue';

const __navBarHelpers = {
    isNewTech() {
        return document.querySelector("meta[name='o365-app-id']") !== null;
    },
    articleIsRenderedInNT() {
        return document.querySelector("meta[name='o365-person-id']") !== null; // We could potentially have a CT app rendered via NT, requiring different API endpoints and params.
    },
    isProxyRequest() {
        return document.querySelector("meta[name='o365-proxy-request']") !== null;
    },
    translate() {
        if (this.isNewTech()) {
            return window.$t || (s => s);
        } else {
            return (str) => {
                if (window.__navBarData?.translations && window.__navBarData.translations[str]) {
                    return window.__navBarData.translations[str];
                } else {
                    return str;
                }
            }
        }
    },
    getQueryParam(paramKey) {
        // Case insensitive retrieval
        const queryParams = new URLSearchParams(window.location.search);
        const paramKeyNormalized = paramKey?.toLowerCase();
        const key = [...queryParams.keys()].find(k => k.toLowerCase() === paramKeyNormalized);
        return queryParams.get(key);
    },
    hideNavParams() {
        const hideNavValue = this.getQueryParam("HideNav");
        return !!hideNavValue && hideNavValue.toLowerCase() === "true";
    },
    isOnHomePage() {
        return window.location.pathname === "/";
    },
    isIOS() {
        if (/iPad|iPhone|iPod/.test(navigator.platform)) {
            return true;
        } else {
            return navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel/.test(navigator.platform);
        }
    },
    isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        } else {
            return this.isIOS() ? true : false;
        }
    }
};

const __dataAccess = {
    execProc({ procName, data }) {
        const isNewTech = __navBarHelpers.isNewTech();
        const articleIsRenderedInNT = __navBarHelpers.articleIsRenderedInNT();
        return _fetch(isNewTech || articleIsRenderedInNT ? `/nt/api/data/${procName}` : `/api/apps/data/${procName}`, {
            method: "POST",
            body: isNewTech || articleIsRenderedInNT ?
                JSON.stringify({
                    Timeout: 30,
                    UseTransaction: true,
                    Operation: "execute",
                    ProcedureName: procName,
                    Values: data
                })
                : JSON.stringify({
                    timeout: 30,
                    operation: "execute",
                    procedure: procName,
                    ...data
                }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then(r => r.json()).then(d => d.success);
    },
    getDataFromView({ viewName, whereClause, sortOrder, groupBy, maxRecords, distinctRows, fields, skip }) {
        const isNewTech = __navBarHelpers.isNewTech();
        const articleIsRenderedInNT = __navBarHelpers.articleIsRenderedInNT();
        return _fetch(isNewTech || articleIsRenderedInNT ? `/nt/api/data/${viewName}` : `/api/apps/data/${viewName}`, {
            method: "POST",
            headers: { "Content-Type": "application/json", },
            body: JSON.stringify({
                "whereClause": whereClause || "",
                "sortOrder": sortOrder || [],
                "groupBy": groupBy || [],
                "maxRecords": maxRecords || 25,
                "distinctRows": distinctRows || false,
                "skip": skip || 0,
                "fields": fields || [],
                "operation": "retrieve",
                "viewName": viewName
            }),
        }).then(r => r.json()).then(d => d.success?.data || d.success).then(d => maxRecords === 1 ? d[0] : d);
    }
};

const contextSelector = (function () {
    'use strict';
    const { execProc, getDataFromView } = __dataAccess;
    const $d = document;
    const isNewTech = __navBarHelpers.isNewTech();
    const aT = __navBarHelpers.translate();
    const events = { "onContextChanged": [], "onNavigateToSet": [] };

    let state = {
        context: __navBarData?.user?.contextId,
        contextObject: {
            /** @type number */
            ID: __navBarData?.user?.contextId,
            /** @type string */
            name: __navBarData?.orgUnit?.name,
            /** @type string */
            AccessIdPath: __navBarData?.orgUnit?.accessIdPath,
            /** @type string */
            IdPath: __navBarData?.orgUnit?.idPath,
            /** @type string */
            idPath: __navBarData?.orgUnit?.idPath,
            /** @type string */
            OrgUnitName: __navBarData?.orgUnit?.orgUnit,
            /** @type number | null */
            Domain_ID: __navBarData?.orgUnit?.domainId,
            /** @type boolean */
            IsDomain: __navBarData?.orgUnit?.domainId == __navBarData?.orgUnit?.id,
        },
        operationContext: __navBarData?.user?.operationContextId,
        operationContextObject: {},
        homeContext: __navBarData?.user?.contextId,
        updateBreadCrumb: true,
    };

    function updateContext(id, opts, userTriggered, domain, callback) {
        id = Number(id);
        if (isNaN(id)) {
            return;
        }

        const params = { TriggeredByUser: userTriggered ? true : false, ...opts, ID: id };

        // If new context is the same as existing one, then fire an event and stop here.
        // We fire an event here for backwards compatability reasons, many apps expect this event to be fired on page load.
        // if (id == state.context && isNewTech) {
        //     fireEvent("onContextChanged", { ...params, ...state.contextObject });
        //     return;
        // }

        if (!isNewTech) {
            window.$(function () {
                window.af.userSession.contextId = id;
            });
        }

        if (opts && opts.preventSaving) {
            execProc({
                procName: "astp_System_OrgUnitsPersonsCurrentUnitForToolbar",
                data: { OrgUnit_ID: id }
            }).then(result => {
                if (!result) {
                    return;
                }
                const data = result.Table[0];
                params.IdPath = data.IdPath;
                params.idPath = data.IdPath;
                params.OrgUnit = data.OrgUnit;
                params.name = data.OrgUnit;
                params.OrgUnitName = data.OrgUnitName;
                params.Domain_ID = data.Domain_ID;
                params.IsDomain = data.Domain_ID === id;
                fireEvent("onContextChanged", params);

                setContext(id, { ...params, orgUnitData: data }, userTriggered, domain, callback);
            });
        }

        if (!opts || !opts.preventSaving) {
            updateQueryString(id);
            state.homeContext = id;

            _fetch("/nt/api/user/orgunitcontext/set", {
                method: "POST",
                headers: { "Content-Type": "application/json", },
                body: JSON.stringify({ orgUnitId: id, }),
            }).then(async res => {
                const response = await res.json();
                if (!response.success) {
                    console.error(response);
                } else {
                    const data = response.success;
                    params.IdPath = data.idPath;
                    params.idPath = data.idPath;
                    params.OrgUnit = data.orgUnit;
                    params.name = data.orgUnit;
                    params.OrgUnitName = data.orgUnitName;
                    params.Domain_ID = data.domainId;
                    params.IsDomain = data.domainId === id;

                    fireEvent("onContextChanged", params);

                    setContext(id, { ...params, orgUnitData: data }, userTriggered, domain, callback);
                }
            });
        }
    }

    function refreshDataSource(ds, fieldName) {
        fieldName = fieldName || "OrgUnit_ID";

        attachEvent("onContextChanged", pCtx => {
            const newWhereClause = `${fieldName} = ${pCtx.ID}`;
            if (isNewTech) {
                ds.recordSource.whereClause = newWhereClause;
                ds.load();
            } else {
                ds.setParameter("whereClause", newWhereClause);
                ds.refreshDataSource();
            }
        });
    }

    async function setContext(context, opts, userTrigged, _domain, callback) {
        context = Number(context);
        state.context = context;
        state.contextObject = Object.assign({ ID: context }, opts);

        if (!state.context) {
            return;
        }

        let data = null;

        if (opts && opts.orgUnitData) {
            // If we already have relevant Org Unit data, use that.
            data = opts.orgUnitData;
        } else {
            // Otherwise, we load it from server.
            data = await execProc({
                procName: "astp_System_OrgUnitsPersonsCurrentUnitForToolbar",
                data: { OrgUnit_ID: context }
            }).then(result => {
                if (!result) {
                    return null;
                }
                return result.Table[0];
            });
        }

        if (!data) {
            if (callback) {
                callback(state.contextObject);
            }
            return;
        }

        if (userTrigged) {
            if ($d.querySelector(".context-name")) {
                $d.querySelector(".context-name").textContent = data.orgUnitName || data.OrgUnitName;
            }
            if ($d.querySelector(".context-name-lg")) {
                $d.querySelector(".context-name-lg").textContent = data.orgUnitName || data.OrgUnitName;
            }
            if ($d.querySelector("#context-menu .dropdown-usercontext span")) {
                $d.querySelector("#context-menu .dropdown-usercontext span").textContent = data.orgUnit || OrgUnit;
            }
            if (state.updateBreadCrumb) {
                if ($d.querySelector(".home-context-name")) {
                    $d.querySelector(".home-context-name").textContent = data.orgUnitName || data.OrgUnitName;
                }
                if ($d.querySelector(".home-context-name-lg")) {
                    $d.querySelector(".home-context-name-lg").textContent = data.orgUnit || data.OrgUnit;
                }
            }
        } else if (opts?.preventSaving && state.updateBreadCrumb) {
            if ($d.querySelector(".home-context-name")) {
                $d.querySelector(".home-context-name").textContent = data.orgUnitName || data.OrgUnitName;
            }
            if ($d.querySelector(".home-context-name-lg")) {
                $d.querySelector(".home-context-name-lg").textContent = data.orgUnit || data.OrgUnit;
            }
        }

        state.contextObject = {
            ID: context,
            name: data.OrgUnitNameAndTitle,
            AccessIdPath: data.AccessIdPath,
            IdPath: data.idPath || data.IdPath,
            idPath: data.idPath || data.IdPath,
            OrgUnitName: data.orgUnitName || data.OrgUnitName,
            Domain_ID: data.Domain_ID,
            IsDomain: data.Domain_ID === context,
        };

        if (callback) {
            callback(state.contextObject);
        }
    }

    async function updateOperationContext(orgUnitId, opts) {

        if (!orgUnitId) {
            return;
        }

        // if (orgUnitId !== state.operationContext) {

        // }

        // Update operationContext in DB
        await _fetch("/nt/api/user/operationcontext/set", {
            method: "POST",
            headers: { "Content-Type": "application/json", },
            body: JSON.stringify({ orgUnitId }),
        }).then(async res => {
            const response = await res.json();
            if (!response.success) {
                console.error(response);
            } else {
                // TODO: Call onContextChanged from here and skip view fetch?
            }
        });


        const procCallResult = await execProc({
            procName: "astp_System_OrgUnitsPersonsCurrentUnitForToolbar",
            data: { OrgUnit_ID: orgUnitId }
        });

        if (!procCallResult) {
            console.warn("Tried to load context data for '" + orgUnitId + "' but failed to retrieve any data");
            return;
        }

        const data = procCallResult.Table[0];
        state.operationContext = orgUnitId;
        state.operationContextObject = {
            ID: orgUnitId,
            name: data.OrgUnitNameAndTitle,
            AccessIdPath: data.AccessIdPath,
            IdPath: data.IdPath,
            idPath: data.IdPath,
            OrgUnitName: data.OrgUnitName,
            forceChangeEvent: opts && opts.forceChangeEvent
        };

        fireEvent("onContextChanged", state.operationContextObject);
    }

    function getSessionContext() {
        return state.context;
    }

    function getSessionContextObject() {
        return state.contextObject;
    }

    function getHomeContext() {
        return state.homeContext;
    }

    function setNavigateTo(obj) {
        if (obj.domainOrgUnit_ID && obj.domainOrgUnit) {
            if ($d.querySelector("#context-menu .dropdown-domain span")) {
                $d.querySelector("#context-menu .dropdown-domain span").textContent = obj.domainOrgUnit;
            }
            $d.querySelector("#context-menu .dropdown-domain")?.setAttribute("href", $d.querySelector("#context-menu .dropdown-orgunit").getAttribute("href").split("?")[0] + "?Context=" + obj.domainOrgUnit_ID);
            $d.querySelector("#context-menu .dropdown-domain")?.classList?.remove("d-none");
            $d.querySelector('[data-dropdown-target="#context-menu"]')?.setAttribute("title", $t("Current Context: ") + obj.domainOrgUnit + " " + $t("Domain / Project"));
        } else {
            $d.querySelector('[data-dropdown-target="#context-menu"]')?.setAttribute("title", $t("Current Context: ") + obj.orgUnit + " " + $t("(Org Unit From Current Record)"));
        }
        if (obj.orgUnit_ID && obj.orgUnit) {
            if ($d.querySelector("#context-menu .dropdown-orgunit span")) {
                $d.querySelector("#context-menu .dropdown-orgunit span").textContent = obj.orgUnit;
            }
            $d.querySelector("#context-menu .dropdown-orgunit")?.setAttribute("href", $d.querySelector("#context-menu .dropdown-orgunit").getAttribute("href").split("?")[0] + "?Context=" + obj.orgUnit_ID);
            $d.querySelector("#context-menu .dropdown-orgunit")?.classList?.remove("d-none");
        }
        state.updateBreadCrumb = false;
        fireEvent("onNavigateToSet", obj);
    }

    function replaceQueryParam(param, newVal, search) {
        search = search || location.search;
        const regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
        const query = search.replace(regex, "$1").replace(/&$/, '');
        return (query.length > 2 ? query + "&" : "?") + (newVal ? param + "=" + newVal : '');
    }

    function updateQueryString(orgUnitId) {
        const url = window.location.pathname.replace(/\/\/+/, '/') + replaceQueryParam("Context", orgUnitId);
        history.replaceState({ ID: orgUnitId }, '', url + window.location.hash);
    }

    function attachEvent(eventName, handlerFunc) {
        if (!events.hasOwnProperty(eventName)) {
            throw new Error(`The event '${eventName}' does not exist`);
        }
        events[eventName].push(handlerFunc);
    }

    function detachEvent(eventName, handlerFunc) {
        if (!events.hasOwnProperty(eventName)) {
            throw new Error(`The event '${eventName}' does not exist`);
        }
        events[eventName] = events[eventName].filter(f => f !== handlerFunc);
    }

    function fireEvent(eventName, data) {
        events[eventName]?.forEach(handlerFunc => handlerFunc(data));
    }

    return { attachEvent, detachEvent, updateContext, refreshDataSource, getSessionContext, getSessionContextObject, setNavigateTo, setContext, updateOperationContext, getHomeContext };
})();

// This is a wrapper built around contextSelector, for backwards compatability purposes
const orgunit_lookup = (() => {
    function attachEvent(eventName, callbackFunc) {
        if (eventName !== "onOrgunitChanged") {
            throw new Error(`Event '${eventName}' is not supported anymore`);
        }
        contextSelector.attachEvent("onContextChanged", (obj) => {
            callbackFunc({
                orgUnit_ID: obj.ID,
                orgUnitName: obj.OrgUnitName,
                idPath: obj.idPath || obj.IdPath,
                contextSelector: null /* ?? */,
            });
        });
    }

    function detachEvent(eventName, callbackFunc) {
        if (eventName !== "onOrgunitChanged") {
            throw new Error(`Event '${eventName}' is not supported anymore`);
        }
        contextSelector.detachEvent("onContextChanged", callbackFunc);
    }

    function idPath() {
        return contextSelector.getSessionContextObject().idPath || contextSelector.getSessionContextObject().IdPath;
    }

    function orgUnitID() {
        return contextSelector.getSessionContext();
    }
    function changeOrgunit(orgunit_ID, _orgunitName, _idPath, preventSaving) {
        contextSelector.updateContext(orgunit_ID, { preventSaving });
    }

    function currentContext() {
        return contextSelector.getSessionContext();
    }

    function getSessionContext() {
        return contextSelector.getSessionContext();
    }

    function addAutoContextUpdatingForID(dataObject) {
        contextSelector.refreshDataSource(dataObject, "ID");
    }

    function addAutoContextUpdatingForIdPath() {
        // Deprecated. Cannot find any uses of this in code-search.
        throw new Error(`The ${addAutoContextUpdatingForIdPath.name} method is deprecated. Please switch to using contextSelector instead.`);
    }

    function updateQueryString(orgUnitId) {
        // Deprecated. Cannot find any uses of this in code-search.
        throw new Error(`The ${updateQueryString.name} method is deprecated. Please switch to using contextSelector instead.`);
    }

    function setOrgUnitParams(pOrgUnit_ID, pOrgunitName, pIdPath) {
        // Deprecated. Cannot find any uses of this in code-search.
        throw new Error(`The ${setOrgUnitParams.name} method is deprecated. Please switch to using contextSelector instead.`);
    }

    return { attachEvent, detachEvent, idPath, setOrgUnitParams, orgUnitID, updateQueryString, changeOrgunit, currentContext, getSessionContext, addAutoContextUpdatingForID, addAutoContextUpdatingForIdPath };
})();

// Breadcrumb features
const breadcrumb = (() => {
    function setBreadCrumb(str) {
        document.title = str;
        document.querySelectorAll(".app-title").forEach($el => ($el.textContent = str));
    }

    function setBreadCrumbHtml(htmlContent) {
        document.querySelectorAll(".leading-li").forEach($leadingLi => {
            $leadingLi.innerHTML = "";
            while ($leadingLi.nextElementSibling !== null) {
                $leadingLi.nextElementSibling.remove();
            }
            $leadingLi.classList.remove("mx-2");
            $leadingLi.classList.add("ml-2");
            $leadingLi.innerHTML = "|";
            $leadingLi.insertAdjacentHTML("afterend", htmlContent);
        });
    }

    function setParentArticleContent(link) {
        if (link) {
            document.querySelectorAll(".parent-article-link.dropdown-domain,.parent-article-link.dropdown-orgunit").forEach($el => {
                const href = `${link}?${document.querySelector(".parent-article-link").getAttribute("href").split("?")[1]}`;
                $el.setAttribute("href", href);
            });
            document.querySelectorAll(".parent-article-link.dropdown-usercontext").forEach($el => $el.setAttribute("href", link));
        }
    }

    function setParentArticleLabel(text) {
        if (!text) {
            return;
        }
        document.querySelectorAll(".af-parent-article-title").forEach($el => ($el.textContent = text));
    }

    return { setBreadCrumb, setBreadCrumbHtml, setParentArticleContent, setParentArticleLabel };
})();
window["breadcrumb"] = breadcrumb;

// NavBar
const { initNavBar, setContextSelectorOptions } = (function () {
    'use strict';
    const isNewTech = __navBarHelpers.isNewTech();
    const isProxyRequest = __navBarHelpers.isProxyRequest();
    const aT = __navBarHelpers.translate();
    const serverData = __navBarData || {};
    const $d = document;
    const { getDataFromView, execProc } = __dataAccess;
    const isBootstrap5 = $d.querySelector('link[href*="bootstrap/v5"]' /* Will look for a link element that CONTAINS 'bootstrap/v5' */) !== null;
    const isMobile = __navBarHelpers.isMobile();
    let options = {};

    const repository = {
        notifications: {
            hasUnreadNotifications: () => getDataFromView({ viewName: "sviw_Notify_ItemsRead", maxRecords: 1, fields: [{ name: "Unread" }] })
        },
        devMenu: {
            jobsFailing: () => getDataFromView({ viewName: "sviw_System_JobsFailing", maxRecords: 1, fields: [{ name: "ID", aggregate: "COUNT" }] }),
            ntJobsFailing: () => getDataFromView({ viewName: "sviw_O365_Jobs", maxRecords: 1, whereClause: "StatusText = 'Error'", fields: [{ name: "ID", aggregate: "COUNT" }] }),
            databaseSetup: () => getDataFromView({ viewName: "stbv_Database_Setup", maxRecords: 1, fields: [{ name: "NTJobsUrl" }, { name: "CodeModules" }, { name: "ToolbarNotificationMessage" }, { name: "FeedbackUrl" }, { name: "Warning" }, { name: "WarningTitle" } ] }),
            transactionsFailing: () => getDataFromView({ viewName: "stbv_Deploy_Transactions", maxRecords: 1, whereClause: "Status = 2", fields: [{ name: "ID", aggregate: "COUNT" }] }),
            lastApplied: () => getDataFromView({ viewName: "sviw_Deploy_TransactionsLastApplied", maxRecords: 1, fields: [{ name: "Applied", type: "datetime2" }] }),
            articleSourceUrl: () => getDataFromView({ viewName: "sviw_System_ArticleAndAppOriginUrls", maxRecords: 1, fields: [{ name: "OriginUrl" }], whereClause: `ArticleID = '${serverData.articleId}' AND IsNT = ${isNewTech ? 1 : 0}` }),
            products: () => getDataFromView({ viewName: "stbv_O365_Products", fields: [{ name: "ID" }, { name: "Name" }, { name: "OrgUnit_ID" }] }),
        },
        context: {
            currentContextOrgUnit: () => {
                //getDataFromView({ viewName: "sviw_System_OrgUnitsPersonsCurrentUnitForToolbar", maxRecords: 1, fields: [{ name: "OrgUnit" }, { name: "OrgUnit_ID" }, { name: "Name" }, { name: "IdPath" }, { name: "AccessIdPath" }] })
                return execProc({ procName: "astp_System_OrgUnitsPersonsCurrentUnitForToolbar", data: { OrgUnit_ID: null } }).then(result => {
                    if (!result) {
                        return null;
                    }
                    return result.Table[0];
                });
            },
            myPersonContext: () => getDataFromView({ viewName: "sviw_System_MyPerson", maxRecords: 1, fields: [{ name: "Context_ID" }, { name: "CurrentContext" }, {name:"EnableFeedback"}] })
        },
        user: {
            privacyPolicy: () => getDataFromView({ viewName: "stbv_System_PersonsUsers", fields: [{ name: "ID" }, { name: "PrivacyPolicyApproved" }], whereClause: `Person_ID = ${serverData.user.id} AND PrivacyPolicyApproved IS NOT NULL` /* AND WebSite = '${serverData.alias}' */ }),
            getEmail: () => getDataFromView({ viewName: "sviw_System_MyPerson", fields: [{ name: "Email" }], maxRecords: 1 }),
        },
        relatedPages: {
            getRelatedPages: () => getDataFromView({ viewName: "sviw_WebSiteCMS_ArticlesRelatedArticlesForToolbar", whereClause: `RelatedArticleID = '${serverData.articleId}' AND HostName = '${serverData.hostName}'`, maxRecords: -1, fields: [{ name: "ArticleID" }, { name: "RelatedArticleID" }, { name: "ArticleTitle" }, { name: "QueryString" }] }),
        },
        operationContext: {
            myCurrentOperationContext: () => getDataFromView({ viewName: "sviw_System_MyOperationContext", maxRecords: 1, fields: [{ name: "ID" }, { name: "IdPath" }, { name: "Domain_ID" }, { name: "OrgUnit" }, { name: "OrgUnitName" }] }),
            myOrgUnitRoles: () => getDataFromView({ viewName: "sviw_System_MyOperationsOrgUnitsRolesForContext", distinctRows: true, maxRecords: -1, fields: [{ name: "OrgUnit" }, { name: "OrgUnit_ID" }] }),
        },
    };

    const ui = {
        show: ($element) => $element?.classList?.remove("d-none"),
        hide: ($element) => $element?.classList?.add("d-none"),
        addClass: ($element, className) => $element?.classList?.add(className),
        removeClass: ($element, className) => $element?.classList?.remove(className),
        showModal($modal) {
            if (isNewTech || isBootstrap5) {
                window.bootstrap.Modal.getOrCreateInstance("#" + $modal.id).show();
            } else {
                window.$($modal).modal("show");
            }
        },

        hideModal($modal) {
            if (isNewTech || isBootstrap5) {
                window.bootstrap.Modal.getOrCreateInstance("#" + $modal.id).hide();
            } else {
                window.$($modal).modal("hide");
            }
        }
    };

    function initNavBar(opts) {
        options = opts || {};
        const hideNav = __navBarHelpers.hideNavParams();
        if (serverData.hideNav || serverData.hideNavCookie || hideNav) {
            getViewControllers().startingContext(); // We still need to initialize the contextSelector, since it might be in use, despite NavBar being hidden.
            setAfDesktopTopProperty(); // Since we are no longer showing the navbar, we need to set the --af-desktop-top CSS variable to 0, so that content gets pushed up to top of page.
            return;
        }
        const navBarHtml = renderInitialMarkup(opts);
        $d.querySelector("body").insertAdjacentHTML("afterbegin", navBarHtml); // Insert html string into DOM
        initializeViewControllers();
        saveRecentArticle();
    }

    if (!isNewTech) {
        initNavBar();
    }

    function renderInitialMarkup(opts) {
        let navBarHtml = "";
        if (isInOperationMode()) {
            // Operation mode
            navBarHtml = `
                ${operationModeNav()}
            `;
        } else {
            // Default mode
            navBarHtml = `
                ${articleCaretDropdown()}
                ${mobileStyleCheck()}
                ${mainNav()}
                ${mobileNav()}
                ${renderModals()}
            `;
        }

        return `          
            ${!isNewTech ? /* Text color for navbar text */
                `<style>
                #main-nav .navbar-text, #main-nav .nav-link {
                    color: whitesmoke !important;
                }
            </style>` : ""}
            ${navBarHtml}
        `
    }

    function initializeViewControllers() {
        const controllers = getViewControllers();
        Object.values(controllers).forEach(c => c());
    }

    function isInOperationMode() {
        if (options.operationMode) { // Passed as prop into ONavBar component
            return true;
        } else if (serverData.toolbarMode === "operation") { // Toolbarmode in SiteContext
            return true;
        }
        const operationModeValue = __navBarHelpers.getQueryParam("operation-mode"); // Query param operation-mode == true
        return !!operationModeValue && operationModeValue.toLowerCase() === "true";
    }

    function saveRecentArticle() {
        if (isNewTech) {
            execProc({ procName: "sstp_o365_Recent", data: { App_ID: serverData.articleId, Register_ID: (new URLSearchParams(window.location.search)).get("Register_ID") } });
        } else {
            execProc({ procName: "sstp_WebSiteCMS_Recent", data: { HostName: serverData.hostName, ArticleID: serverData.articleId } });
        }
    }

    // In mega-menu and notifications list, we need to manually handle anchor tag click events, since they by default, only change the src of the iframe itself, not the parent page.
    function addIFrameLinkHandler($iframe, options) {
        $iframe?.addEventListener("load", () => {
            $iframe.contentWindow?.addEventListener("click", evt => {
                if (evt.target.nodeName === "A" || evt.target.closest("a")) {
                    const href = evt.target.getAttribute("href") || evt.target.closest("a")?.getAttribute("href");
                    if (href && !href.startsWith("#") && !href.startsWith("javascript:void(0)")) {
                        if (options?.alwaysOpenInNewTab || evt.ctrlKey) {
                            // If CTRL key is pressed while clicking, we open up in new tab
                            window.open(href, "_blank");
                        } else {
                            window.location = href;
                        }
                        evt.preventDefault();
                    }
                }
            });
        });
    }

    /* VIEWS */
    function profileImageDropdownMenu() {
        const showChangePassword = ["email", "mobile", "sql login"].includes(serverData.userType?.toLowerCase());
        const showDeleteMyAccount = serverData.urlHost === "talent.omega365.com" || serverData.urlHost === "dev-test.omega365.com";

        // Only show on Omega sites (HOTFIX, 05.03.24)
        const showMyNotesButton = isNewTech && (serverData.urlHost === "dev-test.omega365.com" || serverData.urlHost === "omega.omega365.com");

        // Set My Profile link
        let myProfileSite = "";
        if (serverData.myProfileSite || serverData.myProfileSite2) {
            myProfileSite = (serverData.myProfileSite || serverData.myProfileSite2);
        }

        return `
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-right" id="picture-caret-dropdown" tabindex="-1">
                ${!serverData.hideUserAccountApp ? `<a class="dropdown-item" href="/user" tabindex="0">${$t("User Account")}</a>` : ""}                
                ${myProfileSite ? `<a class="dropdown-item" href="${myProfileSite}">${$t("My Profile")}</a>` : ""}
                ${serverData.privacyPolicyUrl ? `<button class="dropdown-item" data-toggle="modal" data-bs-toggle="modal" data-target="#af-toolbar-pp-dlg" data-bs-target="#af-toolbar-pp-dlg">${$t("Privacy Policy")}</button>` : ""}                
                ${showChangePassword ? `<button class="dropdown-item" data-toggle="modal" data-bs-toggle="modal" data-target="#user-new-password" data-bs-target="#user-new-password">${$t("Change Password")}</button>` : ""}                
                ${showDeleteMyAccount ? `<a class="dropdown-item" data-toggle="modal" data-bs-toggle="modal" data-target="#af-confirm-account-deletion-modal" data-bs-target="#af-confirm-account-deletion-modal" data-personorgunit="${serverData.user.orgUnitId}">${$t("Delete my Account")}</a>` : ""}
                <div class="dropdown-divider"></div>
                <button class="dropdown-item" data-toggle="modal" data-bs-toggle="modal" data-target="#af-bookmark-url-modal" data-bs-target="#af-bookmark-url-modal">${$t("Bookmark this url")}</button>
                ${showMyNotesButton ? `<button class="dropdown-item d-none" id="nav-my-notes-btn">${$t("My Notes")}</button>` : ''}
                ${isNewTech ? `<button class="dropdown-item" id="nav-clear-storage">${$t("Clear Browser Local Storage")}</button>` : ''}
                ${isNewTech ? `<button class="dropdown-item" id="nav-toggle-spellcheck">${$t("Toggle Spellcheck")}</button>` : ''}
                ${isNewTech ? `<a class="dropdown-item" id="restore-deleted-rows" href="/system-deleted-rows">${$t("Restore Deleted Rows")}</a>` : ''}
                
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="${ "/logout?returnUrl="+encodeURI(location.pathname + location.search)}">${$t("Log out")}</a>
            </div>`
    }

    function articleCaretDropdown() {
        return `
        <div class="dropdown-menu border-primary" id="article-caret-dropdown">
            <button class="dropdown-item aftoolbar-copyurl">${$t("Copy URL")}</button>
            <button class="dropdown-item aftoolbar-copyurl-context">${$t("Copy URL incl. context setting")}</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item aftoolbar-addbookmark">${$t("Add a menu bookmark to this app/url")}</button>
        </div>`;
    }

    function contextDropdownMenu() {
        return `
        <div class="dropdown-menu border border-primary font-weight-bold" id="context-menu" style="min-width:300px;">
            <span style="text-transform: uppercase;" class="pl-3 text-muted parent-article-title">
                ${$t("Navigate To")}: <span class="font-weight-bold"></span> <span class="af-parent-article-title">${serverData.parentArticleTitle}</span>
            </span>
            <div class="dropdown-divider parent-article-title"></div>
            <small class="pl-3 text-muted">
                ${$t("Set Context =")}
            </small>
            <a class="dropdown-item parent-article-link dropdown-domain d-none"  href="${isNewTech ? '/nt' : ''}/${serverData.parentArticleId}?Context=${serverData.orgUnit.id}" >
                1) ${$t("Domain / Project")}: <span class="font-weight-bold"></span>
            </a>
            <a class="dropdown-item parent-article-link dropdown-orgunit d-none" href="${isNewTech ? '/nt' : ''}/${serverData.parentArticleId}?Context=${serverData.orgUnit.id}" >
                2) ${$t("Org Unit From Current Record")}: <span class="font-weight-bold"></span>
            </a>
            ${serverData.parentArticleId ?
                `<a class="dropdown-item parent-article-link dropdown-usercontext" data-context-orgunit-id="${serverData.user.contextId}" href="${isNewTech ? '/nt' : ''}/${serverData.parentArticleId}?Context=${serverData.user.contextId}" >
                3) ${$t("My Selected Home Context")}: <span class="font-weight-bold">${serverData.user.currentContextName}</span>
            </a>`
                : `<a class="dropdown-item  dropdown-usercontext" data-context-orgunit-id="${serverData.user.contextId}" href="${isNewTech ? '/nt' : ''}/${serverData.articleId}?Context=${serverData.user.contextId}" >
                    3) ${$t("My Selected Home Context")}: <span class="font-weight-bold">${serverData.user.currentContextName}</span>
                </a>`}
        </div>
        `
    }

    function developerDropdownMenu() {
        return `
            <style>
                #af-toolbar-menu .dropdown-submenu {
                    position: relative;    
                }

                #af-toolbar-menu .dropdown-submenu>.dropdown-menu {
                    top: 0;
                    left: -168px;
                    min-width: 175px;
                    margin-top: 3px;
                    margin-right: 1px;
                    -webkit-border-radius: 0 6px 6px 6px;
                    -moz-border-radius: 0 6px 6px;
                    border-radius: 0 6px 6px 6px;
                }

                #af-toolbar-menu .dropdown-submenu:hover>.dropdown-menu {
                    display: block;
                }

                #af-toolbar-menu .dropdown-submenu>button:after {
                    display: block;
                    content: " ";
                    float: left;
                    width: 0;
                    height: 0;
                    border-color: transparent;
                    border-style: solid;
                    border-width: 5px 0 5px 5px;
                    border-left-color: grey;
                    margin-top: 8px;
                    margin-left: -10px;
                    rotate: 180deg;
                }

                #af-toolbar-menu .dropdown-submenu.pull-left {
                    float: none;
                }

                #af-toolbar-menu .dropdown-submenu.pull-left>.dropdown-menu {
                    right: -100%;
                    margin-left: 10px;
                    -webkit-border-radius: 6px 0 6px 6px;
                    -moz-border-radius: 6px 0 6px 6px;
                    border-radius: 6px 0 6px 6px;
                }
                .testclass {
                    max-height: max(100px, calc(100vh - 500px));
                    overflow-y: auto;
                }
                .testclass2 {
                    max-height: calc(100vh - 50px);
                    overflow-y: scroll;
                }
            </style>
            <div id="af-toolbar-menu" class="dropdown-menu dropdown-menu-right dropdown-menu-end">
                <a class="dropdown-item" href="/nt/appdesigner-articlelist?">${$t("Article List")}</a>
                ${isNewTech ?
                /* NT only */
                `<a class="dropdown-item" href="/nt/appdesigner?">${$t("New app")}</a>
                <a id="developerEditApp" class="dropdown-item" href="${serverData.devUrl}/nt/appdesigner?ID=${serverData.articleId}">${$t("Edit app")}</a>
                <a class="dropdown-item js-dev-menu-source-url" href="${serverData.articleSourceUrl}/nt/appdesigner?ID=${serverData.articleId}">${$t("Edit app at source")}</a>                
                ${serverData.articleSourceUrl ?
                    `<a class="dropdown-item js-dev-menu-source-url" href="${serverData.articleSourceUrl}/${serverData.articleId}" title="${$t("Open app in source")}">${$t("Open in source")}</a>`
                    : ""}

                <div class="dropdown-divider"></div>                

                <a class="dropdown-item" href="/sitesetup">${$t("Site Setup")}</a>        
                <a class="dropdown-item" href="/nt/siteconfig">${$t("Site Configuration")}</a>        
                <a class="dropdown-item" href="/db-manager">${$t("Database Manager")}</a>                
                <a class="dropdown-item" href="/nt/code-snippets-editor">${$t("Code Snippets Editor")}</a>                

                ${serverData.jobsUrl ?
                    `<a class="dropdown-item" target="_blank" rel="noreferrer noopener" href="${serverData.jobsUrl}">${$t("Jobs")} <span id="failing-jobs-warning-pill" class="d-none badge badge-pill badge-danger"></span></a>`
                    : `<button class="dropdown-item jobsurl-missing">${$t("Jobs")}  <span id="failing-jobs-warning-pill" class="d-none badge badge-pill badge-danger"></span></button>`}
                <a class="dropdown-item" href="/nt/db-jobs" id="new-tech-jobs-link" rel="noopener noreferrer" target="_blank">${$t("Jobs (NT)")} <span id="nt-failing-jobs-warning-pill" class="d-none badge badge-pill badge-danger"></span></a>

                <div class="dropdown-divider"></div>
                
                <a class="dropdown-item" href="/nt/log">${$t("Log")}</a>`
                /* NT end */
                :
                /* CT only */
                `<a class="dropdown-item" href="/appdesigner-ct?Apps">${$t("New app")}</a>
                <a id="developerEditApp" class="dropdown-item" href="/appdesigner-ct?Apps/${serverData.articleId}">${$t("Edit app")}</a>
                <a class="dropdown-item js-dev-menu-source-url" href="${serverData.articleSourceUrl}/appdesigner-ct?Apps/${serverData.articleId}">${$t("Edit app at source")}</a>
                ${serverData.isTestMode ?
                    `<a class="dropdown-item" href="${serverData.productionUrl}/${serverData.articleId}?${serverData.queryString}" title="${$t("Open app in production")}">${$t("Open in prod")}</a>`
                    : `<a class="dropdown-item js-dev-menu-source-url" href="${serverData.devUrl}/${serverData.articleId}?${serverData.queryString}" title="${$t("Open app in dev")}">${$t("Open in dev")}</a>`}
                ${serverData.articleSourceUrl ?
                    `<a class="dropdown-item js-dev-menu-source-url" href="${serverData.articleSourceUrl}/${serverData.articleId}" title="${$t("Open app in source")}">${$t("Open in source")}</a>`
                    : ""}

                <div class="dropdown-divider"></div>

                <a id="developerSiteSetup" class="dropdown-item" href="${serverData.devUrl}/sitesetup-ct">${$t("Site setup")}</a>
                <a id="developerDbManager" class="dropdown-item" href="${serverData.devUrl}/db-manager">${$t("Database Manager")}</a>                
                ${serverData.jobsUrl ?
                    `<a class="dropdown-item" target="_blank" rel="noreferrer noopener" href="${serverData.jobsUrl}">${$t("Jobs")} <span id="failing-jobs-warning-pill" class="d-none badge badge-pill badge-danger"></span></a>`
                    : `<button class="dropdown-item jobsurl-missing">${$t("Jobs")}  <span id="failing-jobs-warning-pill" class="d-none badge badge-pill badge-danger"></span></button>`}
                <a class="dropdown-item" href="/nt/db-jobs" id="new-tech-jobs-link" rel="noopener noreferrer" target="_blank">${$t("Jobs (NT)")} <span id="nt-failing-jobs-warning-pill" class="d-none badge badge-pill badge-danger"></span></a>

                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="${serverData.productionUrl}/system-assemblies">${$t("Assemblies")}</a>`
                /* CT end */}                
                
                <a class="dropdown-item" href="${serverData.devUrl}/nt/db-updater">${$t("DB-Updater")}<span id="failing-transactions-warning-pill" class="d-none badge badge-pill badge-danger"></span></a>                            
                <div class="dropdown-submenu">
                    <button class="dropdown-item">${$t("Debug")}</button>
                    <div class="dropdown-menu testclass">
                        <a class="dropdown-item" href="${isNewTech ? '/nt/' : ''}/api/debug/cache">${$t("Cache")}</a>
                        <a class="dropdown-item" target="_blank" href="/nt/code-search/">${$t("Code Search")}</a>
                        <a class="dropdown-item" target="_blank" href="/errorlog/">${$t("Errorlog")}</a>
                        <a class="dropdown-item" target="_blank" href="/trace/">${$t("Trace")}</a>
                        <a class="dropdown-item" target="_blank" href="/db-changes/">${$t("db-changes")}</a>
                        <a class="dropdown-item" target="_blank" href="/Bugrecorder/">${$t("Bugrecorder")}</a>
                        <a class="dropdown-item" href="/nt/mail-messages/">${$t("Mail-Messages")}</a>
                        <a class="dropdown-item" href="/nt/sms-messages/">${$t("SMS-Messages")}</a>
                        <a class="dropdown-item" href="/api/debug/razor">${$t("Razor")}</a>
                        <a class="dropdown-item" href="/api/debug/razor-markup">${$t("Razor (NT)")}</a>
                        <a class="dropdown-item" href="/api/debug/articles">${$t("Articles")}</a>
                        <a class="dropdown-item" href="/api/debug/placeholders">${$t("Placeholders")}</a>
                        <a class="dropdown-item" href="/nt/debug-routes">${$t("Routehandlers (NT)")}</a>
                        <a class="dropdown-item" href="/api/debug/routes">${$t("Routehandlers (CT)")}</a>
                        ${isNewTech ? '<a class="dropdown-item" href="/nt/api/debug/static-files">Static Files</a>' : ''}
                        <a class="dropdown-item" href="/api/debug/trace">${$t("DB Trace")}</a>
                        <a class="dropdown-item" href="/api/debug/profiler">${$t("DB Profiler")}</a>
                        <a class="dropdown-item" href="/api/debug/errors">${$t("Errors")}</a>
                        <a class="dropdown-item" href="/nt/loaded-assemblies">${$t("Assemblies (NT)")}</a>
                        <a class="dropdown-item" href="/api/debug/assemblies">${$t("Assemblies (CT)")}</a>
                        <a class="dropdown-item" href="/nt/o365setup-system-properties">${$t("Properties Config")}</a>
                        <a class="dropdown-item" href="/api/logging/configuration">${$t("Logging Config")}</a>
                        <a class="dropdown-item" href="/swagger">${$t("Swagger")}</a>
                    </div>
                </div>    

                <div class="dropdown-divider"></div>
                <a class="dropdown-item" target="_blank" rel="noopener noreferrer" href="/localized-strings?ProjectName=${isNewTech ? 'AppFile' : 'Apps'}/${serverData.articleId}">${$t("Translations")}</a>
                <a class="dropdown-item" target="_blank" href="${serverData.articleSourceUrl}/websitecms-tooltips?ArticleID=${serverData.articleId}">${$t("Tooltips")}</a>

                <div class="dropdown-divider"></div>
                <i class="dropdown-item">Updated: <span id="dev-menu-last-updated">${$t("Loading")}...</span></i>
                <i class="dropdown-item">${$t('Channel')}: ${serverData.releaseChannel}</span></i>
                ${serverData.articleSourceUrl
                ? `<i class="dropdown-item">Origin: ${serverData.articleSourceUrl}</i>`
                : ""}
            </div>
        `;
    }

    function mobileStyleCheck() {
        // Util to check if we're in app (pwa or flutter) or desktop for both styling and javascript, must load instantly for use in other apps
        if (serverData.isFlutter && serverData.mobileStartupArticle === "pwa") {
            return `
            <style>
                .d-app-block {
                    display: block !important;
                }
                .d-app-none {
                    display: none !important;
                }
            </style>
            <script>
                window.isApp = () => true;
            </script>`
        } else {
            return `
            <style>
                @@media only screen and (display-mode: standalone) {
                    .d-app-block {
                        display: block !important;
                    }
                    .d-app-none {
                        display: none !important;
                    }
                }
            </style>
            <script>
                window.isApp = () => window.matchMedia("only screen and (display-mode: standalone)").matches;
            </script>`;
        }
    }

    function contextMenu() {
        if (serverData.parentArticleId && serverData.parentArticleTitle) {
            return `<li class="nav-item dropdown d-inline-grid" id="user-context-selector-parent">
                        ${serverData.breadcrumbMode == '0' ?
                    `<button id="context-selector-dropdown-container" class="nav-link btn btn-link px-0 overflow-hidden" style="padding-bottom:0px; text-decoration: none;" data-toggle="dropdown" data-bs-toggle="dropdown" title="${$t("Current Context:")} ${serverData.orgUnit.name}">
                                <span class="toolbar-context-title d-inline-block d-lg-none text-truncate app-title" style="max-width: calc(25 * 1vw)">${serverData.articleTitle} </span>
                                <span class="toolbar-context-title d-none d-lg-inline-block text-truncate home-context-name-lg" style="max-width: calc(40 * 1vw)">${serverData.orgUnit.name} </span>
                                <span class="text-truncate d-none d-lg-inline-block">: <span class="af-parent-article-title">${serverData.parentArticleTitle}</span></span>
                            </button>
                            ${contextDropdownMenu()}`
                    : `<a href="/${serverData.parentArticleId}" class="nav-link px-0 " style="padding-bottom:0px" >${serverData.parentArticleTitle}</a>`}
                </li>
                <li class="nav-item navbar-text mx-1 mx-sm-2 d-none d-lg-inline-block leading-li" id="user-context-selector-splitter">
                    ${isNewTech ?
                    `<i class="bi bi-chevron-right"></i>`
                    : `<i class="fa fa-chevron-right"></i>`}
                </li>
                <li class="nav-item navbar-text text-truncate d-none d-lg-inline-block" id="user-context-selector-article">
                    <span class="app-title">  ${options.title ? options.title : serverData.articleTitle} </span>
                </li>`;
        } else if (serverData.context && serverData.user.contextId && serverData.orgUnit.id !== serverData.user.contextId) {
            return `<li class="nav-item dropdown d-inline-grid" id="user-context-selector-parent" style="cursor:pointer">
                    <button context-selector-dropdown-container class="nav-link btn btn-link px-0 overflow-hidden" style="padding-bottom:0px; text-decoration: none;" data-bs-toggle="dropdown" data-toggle="dropdown">
                        <span class="toolbar-context-title d-inline-block d-lg-none text-truncate app-title" style="max-width: calc(25 * 1vw)"> ${options.title ? options.title : serverData.articleTitle}</span>
                        <span class="toolbar-context-title d-none d-lg-inline-block text-truncate home-context-name-lg" style="max-width: calc(40 * 1vw)">${serverData.orgUnit.name}</span>
                        <span class="text-truncate d-none d-lg-inline-block">:  ${options.title ? options.title : serverData.articleTitle}</span>
                    </button>
                    ${contextDropdownMenu()}
                </li>`;
        } else {
            return `<li class="nav-item navbar-text leading-li app-title text-truncate pb-0" id="user-context-selector-parent" >
                            ${serverData.breadcrumbMode == "0" ?
                    `<span class="toolbar-context-title d-inline-block d-lg-none text-truncate app-title" style="max-width: calc(25 * 1vw)"> ${options.title ? options.title : serverData.articleTitle}</span>
                            <span class="toolbar-context-title d-none d-lg-inline-block text-truncate home-context-name-lg" style="max-width: calc(40 * 1vw)">${serverData.orgUnit.name}</span>
                            <span class=" text-truncate  d-none d-lg-inline-block">: ${options.title ? options.title : serverData.articleTitle}</span>`
                    : `<span class="text-truncate d-none d-lg-inline-block"> ${options.title ? options.title : serverData.articleTitle}</span>`}                                
                        </li>`;
        }
    }

    function getStartupUrl() {
        let url = "/";
        if (serverData.isFlutter) {
            // url = serverData.mobileStartupArticle;
            // quick workaround for serverData.mobileStartupArticle never being implemented
            if (window.location.host === "veidekke.omega365.com") {
                url = "/veidekke-mobile-home2";
            }
            if (window.location.host === "veidekke-test.omega365.com") {
                url = "/veidekke-mobile-home";
            }
        }
        return url;
    }

    function mainNav() {
        let navCol = "af-bg-toolbar"; // Navbar color
        let navColODT = "white-space: nowrap;"; // Navbar style expression
        let hideClass = "";
        let navbarPosClass = "sticky-top";
        const showProfilePicture = !!serverData.user.pictureFileRef;

        if (serverData.hideNav || serverData.hideNavCookie) {
            hideClass = "d-none";
            setAfDesktopTopProperty();
        }

        if (serverData.toolbarColor) {
            navCol = "";
            navColODT += `background-color: ${serverData.toolbarColor} !important;`;
        }

        if (__navBarHelpers.isIOS()) {
            navbarPosClass = "fixed-top"; // Set navbar to fixed position in order to prevent a bug where navbar would scroll up/down while rest of page stayed fixed in position on iOS.
        }

        return `        
        <nav id="main-nav" class="navbar navbar-expand navbar-dark ${navCol} p-1 pl-2 pl-lg--3 ${navbarPosClass} ${hideClass} d-app-none" style="${navColODT}">
            <ul class="navbar-nav flex-row af-navbar-nav" style="min-width: 0;flex-shrink: 1; white-space:nowrap;"> 
                <li class="nav-item">
                    <a class="nav-link" href="#mega-menu" data-bs-toggle="modal" data-toggle="modal" title="${$t("Open Mega Menu")}">
                        ${isNewTech ?
                `<i class="bi bi-list"></i>`
                : `<i class="fa fa-th" aria-hidden="true"></i>`} 
                    </a>
                </li>
                <li class="nav-item pl-1">
                    <a class="nav-link px-sm-3" title="${$t("Home")}" href="${getStartupUrl()}">
                        ${isNewTech ?
                `<i class="bi bi-house-fill"></i>`
                : `<i class="fa fa-home" aria-hidden="true"></i>`}                            
                    </a>
                </li>
                
                ${contextMenu()}
            </ul>

            <ul class="navbar-nav ml-auto flex-row">
                ${!isMobile && window.innerWidth <= 430 ? `
                    <li class="nav-item position-relative">
                        <div class="nav-link mr-1 me-1 mr-sm-2 me-sm-2" id="setup-warning-container" style="vertical-align: super; padding-top: ${isNewTech ? '12' : '10'}px;"></div>
                    </li>
                ` : ""}
                <li class="nav-item position-relative dropdown d-none" id="related-articles-container">
                    <button class="nav-link mr-1 me-1 btn btn-link mr-sm-2 me-sm-2" title="${$t("Related applications")}" data-toggle="dropdown" data-bs-toggle="dropdown" id="button-related-articles">
                        ${isNewTech ? `<i class="bi bi-box-arrow-up-right"></i>` : `<i class="fas fa-external-link-alt"></i>`}                        
                    </button>
                    <div class="dropdown-menu" id="af-related-menu">
                    </div>
                </li>

                ${!isMobile ? `
                <li class="nav-item position-relative d-none">
                    <span class="nav-link mr-1 me-1 mr-sm-2 me-sm-2" id="site-notification-message" style="padding-top: ${isNewTech ? '12' : '10'}px; font-weight: bold;">
                    </span>
                </li>
                <li class="nav-item position-relative dropdown d-none" id="user-feedback-container">
                    <a  target="_blank">
                        <i class="nav-link mr-1 me-1 mr-sm-2 me-sm-2 bi bi-chat-left-text-fill" style="vertical-align: super; padding-top: ${isNewTech ? '12' : '10'}px; cursor: pointer;" title="${$t("Give feedback to Omega 365!")}"  id="af-navbar-user-feedback"></i>
                    </a>
                </li>
                ` : ""} 

                ${serverData.orgUnit.name ?
                `<li class="nav-item ${showProfilePicture ? (isNewTech ? 'mr-3' : 'mr-5') : (isNewTech ? 'mr-2' : "mr-4")}">
                    <button class="nav-link btn btn-link pb-0 h-100" title="${$t("Selected Home Context")}" style="white-space:nowrap; text-decoration: none;" id="user-context-selector-button" data-callback="orgunitChanged">
                        <span class="toolbar-context-title d-inline-block d-lg-none text-truncate context-name" style="max-width: calc(40 * 1vw)">${serverData.user.currentContextName}</span>
                        <span class="toolbar-context-title d-none d-lg-inline-block text-truncate context-name-lg" style="max-width: calc(40 * 1vw)">${serverData.user.currentContextName}</span>
                        ${isNewTech ?
                    `<i class="ms-1 bi bi-caret-down-fill" style="vertical-align: super;"></i>`
                    : `<i class="toolbar-context-title fa fa-caret-down pt-1 px-1" style="vertical-align:super"></i>`}
                    </button>                    
                </li>` : ""}

                <li class="nav-item position-relative dropdown" >
                    <button id="link-megamenu" title="${$t("My Profile / Log Out")}" data-toggle="dropdown" data-bs-toggle="dropdown" class="nav-link ${!isNewTech ? 'position-absolute' : ''} btn btn-link p-0" style="${!showProfilePicture ? `height: 100%; ${!isNewTech ? 'left: -25px; top: -4px' : ''}` : 'left: -40px; top: -4px;'}">
                        ${showProfilePicture ?
                `<img src="/api/file/view/stbv_System_PersonsImages/${serverData.user.pictureFileRef}?mheight=49" class="mr-1 af-toolbar-profile-pic" style="height:45px;">`
                : (isNewTech ? `<i class="bi bi-person-fill my-3" style="font-size: 1.3em;"></i>` : `<i class="fas fa-user-alt my-3"></i>`)}
                    </button>
                    
                    ${profileImageDropdownMenu()}
                </li>

                ${serverData.helpUrl ?
                `<li class="nav-item position-relative mx-2" id="aftoolbar-helpurl-li"></li>`
                : `<li class="nav-item " style="margin-right: 3.5rem !important;"></li>`}

                <li class="nav-item mx-1 mx-lg-2 position-relative">
                    <style>
                        .af-navbar-unread-notif-icon {
                            position: absolute;
                            top: 4px;
                            left: 25px;
                            background-color: red;
                            border-radius: 99px;
                            padding: 6px 6px;
                            font-size: 10px;
                        }
                    </style>
                    <button class="nav-link btn btn-link" title="${$t("Show Notifications")}" id="button-notify-messages" href="#af-navbar-notifications-modal" data-bs-toggle="modal" data-toggle="modal" data-backdrop="false">
                        ${isNewTech ?
                `<i class="bi bi-bell-fill">
                    <span id="unread-notifications-icon" class="meldinger"></span>
                </i>`
                : `<i class="far fa-bell" aria-hidden="true"></i>`}                            
                        <span id="unread-notifications-icon" class="meldinger"></span>
                    </button>
                </li>

                ${serverData.isDeveloper ? `
                    <li class="nav-item d-none d-sm-block dropdown">
                        <button id="button-megamenu" title="${$t("Show Developer Menu")}" class="nav-link btn btn-link px-3" data-toggle="dropdown" data-bs-toggle="dropdown">
                            ${isNewTech ?
                    `<i class="bi bi-code-slash"></i>
                            <i class="bi bi-circle-fill" style="display: none; color: red; font-size: 8px; position: absolute;"></i>`
                    : `<i class="fas fa-code " style="font-size:12px" aria-hidden="true"></i>
                                <i class="fas fa-circle jobs-failing" style="font-size: 8px;position: absolute; color: red;display:none"></i>`}
                        </button>

                        ${developerDropdownMenu()}
                    </li>
                ` : ""}
            </ul>
        </nav>        
        `
    }

    function mobileNav() {
        let navCol = "af-bg-toolbar"; // Navbar color
        let navColODT = ""; // Navbar style expression, optional

        if (serverData.toolbarColor) {
            navCol = "";
            navColODT = `background-color: ${serverData.toolbarColor} !important;`;
        }

        return `
        <div class="d-none d-app-block">
            <nav id="pwa-nav" class="p-0 navbar navbar-expand navbar-dark ${navCol} sticky-top" style="${navColODT}; height: 48px; display: flex;">
                <ul class="ml-2 h-100 navbar-nav align-items-center">
                    <li class="h-100 nav-item flex-shrink-0">
                        <a class="h-100 px-2 nav-link d-flex justify-content-center align-items-center" href="${serverData.mobileStartupArticle}" title="${$t("Home")}"">
                            <i class="fas fa-fw fa-home" style="font-size: 1.25em;" aria-hidden="true"></i>
                        </a>
                    </li>
                </ul>
                <ul class="h-100 navbar-nav flex-shrink-0 align-items-center flex-shrink-1 flex-grow-1 px-1 py-2 text-truncate">
                    ${serverData.orgUnit.name ?
                `<li class="h-100 nav-item text-truncate">
                        <button class="h-100 px-1 nav-link d-flex justify-content-center align-items-center orgunit-chooser text-truncate btn btn-link" data-callback="orgunitChanged">
                            <span class="text-truncate home-context-name-pwa">${serverData.orgUnit.name}</span>
                        </button>
                    </li>` : ""}
                </ul>
            </nav>
        </div>         
        `
    }

    function renderModals() {
        return `
        ${changePasswordModal()}
        ${serverData.privacyPolicyUrl ? privacyPolicyModal() : ""}
        ${helpPagesModal()}
        ${megaMenuModal()}
        ${notificationsModal()}
        ${bookmarkUrlModal()}
        ${deleteAccountConfirmationModal()}
        `;
    }

    function changePasswordModal() {
        return `
        <div class="modal fade" id="user-new-password">
            <div class="modal-dialog" role="document" data-procedure-id="procChangeUserPass" style="max-width:450px;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">${$t("Change Password")}</h5>
                        ${isNewTech ?
                `<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>`
                : `<button type="button" class="close float-right" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" style="margin-right: -20px;margin-top: -9px;">
                            <i class="fa fa-times-circle"></i>
                        </button>`}                        
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>${$t("Old Password")}</label>
                            <input type="password" id="old-password" class="form-control" autocomplete="new-password" data-parameter="OldPassword" required style="max-width:266px;">
                            <small id="passwords-no-match" class="d-none text-danger">${$t("The entered passwords do not match!")}</small>
                            <small id="old-password-wrong" class="d-none text-danger">${$t("Old password is wrong")}</small>
                            <label>${$t("New Password")}</label>
                            <input type="password" id="new-password" class="form-control" style="max-width:266px;">
                            <label>${$t("Confirm New Password")}</label>
                            <input type="password" id="confirm-password" class="form-control" data-parameter="NewPassword" required style="max-width:266px;">                            
                        </div>
                    </div>
                    <div class="modal-footer p-2">
                        <button class="btn btn-sm btn-primary" data-action="execute" id="execute-update-password"> ${$t("Save")}</button>
                        <button class="btn btn-sm btn-outline-primary" data-dismiss="modal" data-bs-dismiss="modal">${$t("Cancel")}</button>
                    </div>
                </div>
            </div>
        </div>`
    }

    function privacyPolicyModal() {
        return `
            <div class="modal fade" id="af-toolbar-pp-dlg">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">${$t("Privacy Policy")}</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div style="height:80vh!important;">
                            <iframe id="af-toolbar-pp-frame" style="width:100%; border:0; height:100%;"></iframe>
                        </div>
                        <div class="modal-footer p-2">
                            <button class="btn btn-sm btn-primary" id="af-toolbar-accept-pp" data-bs-dismiss="modal" data-dismiss="modal"> ${$t("Accept")}</button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    function helpPagesModal() {
        return `
        <div class="modal fade in" data-modal-draggable id="af-help-pages-modal">
            <div class="modal-dialog modal-xl">
                <div class="modal-content" >
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        ${isNewTech || isBootstrap5 ?
                `<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>`
                : `<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>`}
                    </div>
                    <div class="modal-body p-0" style="height:100%">
                        <iframe id="af-help-pages-modal-iframe" class="border-0" style="min-height:430px;width:100%;" ></iframe>
                    </div>
                    <div class="modal-footer p-2 mt-n1">
                        <button class="btn btn-sm btn-primary submitt-from-help-dialog" > ${$t("Submit")}</button>
                        <button class="btn btn-sm btn-outline-primary" data-dismiss="modal" data-bs-dismiss="modal">${$t("Cancel")}</button>
                    </div>
                </div>
            </div>
        </div>`;
    }

    function megaMenuModal() {
        return `
        <div id="mega-menu" class="modal fade in">
            <div class="modal-dialog modal-dialog-scrollable" style="top: 50px; left: 5px; margin: 0; max-width: 1600px; max-height: calc(100vh - 55px);">
                <div class="modal-content">
                    <iframe style="border: 0; width: 100%; height: calc(100vh - 20px);"></iframe>
                </div>                    
            </div>
        </div>`;
    }

    function notificationsModal() {
        return `
        <div id="af-navbar-notifications-modal" class="modal">
            <div class="modal-dialog modal-dialog-scrollable" style="top: 50px; margin: 0; margin-left: auto; max-width: min(100vw,700px); max-height: calc(100vh - 55px);">
                <div class="modal-content">
                    <iframe style="border: 0; width: 100%; height: calc(100vh - 20px);"></iframe>
                </div>                    
            </div>
        </div>`;
    }


    function bookmarkUrlModal() {
        return `
        <div class="modal fade in" id="af-bookmark-url-modal">
            <div class="modal-dialog modal-sm">
                <div class="modal-content" >
                    <div class="modal-header">
                        <h5 class="modal-title">${$t("Save bookmark")}</h5>
                        ${isNewTech ?
                `<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>`
                : `<button type="button" class="close" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>`}                                                
                    </div>
                    <div class="modal-body">
                        <div class="form-group">          
                            <label>${$t("Name")}</label>
                            <input type="text" class="form-control" id="af-bookmark-name">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-sm btn-primary" id="save-bookmark-btn">${$t("Save")}</button>
                        <button class="btn btn-sm btn-outline-primary" data-dismiss="modal" data-bs-dismiss="modal">${$t("Cancel")}</button>
                    </div>
                </div>
            </div>
        </div>
        `;
    }

    function deleteAccountConfirmationModal() {
        return `
        <div class="modal fade in" id="af-confirm-account-deletion-modal">
            <div class="modal-dialog">
                <div class="modal-content" >
                    <div class="modal-header">
                        <h5 class="modal-title">${$t("Are you sure you want to delete your account?")}</h5>
                        <button type="button" class="close" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <span>${$t("An Access Request will be created and your account will be deleted within 30 days.")}</span>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-sm btn-primary" id="initiate-account-deletion">${$t("Save")}</button>
                        <button class="btn btn-sm btn-outline-primary" data-dismiss="modal" data-bs-dismiss="modal">${$t("Cancel")}</button>
                    </div>
                </div>
            </div>
        </div>
        `;
    }

    function operationModeNav() {
        let navCol = "af-bg-toolbar"; // Navbar color
        let navColODT = ""; // Navbar style expression, optional

        if (serverData.toolbarColor) {
            navCol = "";
            navColODT = `background-color: ${serverData.toolbarColor} !important;`;
        }

        return `
            <nav id="main-nav" class="navbar navbar-expand navbar-dark ${navCol} p-1 pl-2 pl-lg--3 sticky-top" style="${navColODT}">
                <ul class="navbar-nav flex-row af-navbar-nav" style="min-width: 0;flex-shrink: 1; white-space:nowrap;"> 
                    <li class="nav-item pl-1">
                        <a class="nav-link px-sm-3" title="${$t("Home")}" href="/">
                            ${isNewTech ?
                `<i class="bi bi-house-door-fill"></i>`
                : `<i class="fa fa-home" aria-hidden="true"></i>`}
                        </a>
                    </li>
                    <li class="nav-item" id="op-context-loading-placeholder" style="color: white; width: 200px; align-self: center;">
                        <p class="placeholder-glow w-100 my-0">
                            <span class="placeholder col-12"></span>
                        </p>
                    </li>                    
                    <li class="nav-item position-relative dropdown d-none" id="op-context-selector-container">
                        <button class="nav-link mr-1 me-1 btn btn-link mr-sm-2 me-sm-2" title="${$t("Select operations context")}" data-toggle="dropdown" data-bs-toggle="dropdown" id="op-context-dropdown-button" style="text-decoration: none;">
                            <span id="current-op-context-label"></span>
                            ${isNewTech ?
                `<i class="ms-1 bi bi-caret-down-fill"></i>`
                : `<i class="fa fa-caret-down pt-1 px-1"></i>`}
                        </button>
                        <div class="dropdown-menu" id="op-context-dropdown-container">
                        </div>
                    </li>
                </ul>

                <ul class="navbar-nav ml-auto flex-row">
                    ${serverData.helpUrl ? `<li class="nav-item position-relative mx-2" id="aftoolbar-helpurl-li"></li>` : ``}
                </ul>
            </nav>        
        `;
    }

    function setAfDesktopTopProperty() {
        $d.querySelector(":root")?.style?.setProperty("--af-desktop-top", "0");
    }

    function makeContextSelectorIFrameSource() {
        // replace string with - orgunit-chooser-toolbar-maks-test
        let orgunitChooserComponent = '/orgunit-chooser-toolbar';

        if (isNewTech) {
            // replace string with orgunit-chooser-toolbar-maks-test
            orgunitChooserComponent = '/nt/orgunit-chooser-toolbar';
        }

        return `${orgunitChooserComponent}?toolbar=true${options.contextWhereClause ? `&whereClause=${encodeURIComponent(options.contextWhereClause)}` : ''}${options.hideOrgUnitsTree ? '&hideTree=true' : ''}`;
    }

    function setContextSelectorOptions(pOptions) {
        if (!pOptions || typeof pOptions !== "object") {
            console.error("Must pass options object as argument");
            return;
        }

        if (typeof pOptions.contextWhereClause === "string" || pOptions.contextWhereClause === undefined || pOptions.contextWhereClause === null) {
            options.contextWhereClause = pOptions.contextWhereClause;
        }

        if (pOptions.hideOrgUnitsTree !== undefined) {
            options.hideOrgUnitsTree = Boolean(pOptions.hideOrgUnitsTree);
        }

        reloadContextSelectorIframe();
    }

    function reloadContextSelectorIframe() {
        const newSrc = makeContextSelectorIFrameSource();
        const $iframe = $d.querySelector("#af-org-unit-chooser-iframe");
        if ($iframe) {
            $iframe.src = newSrc;
        }
    }

    function getViewControllers() {
        return {
            startingContext: () => {
                if (isInOperationMode()) {
                    // Handle operation context a little differently than normal context
                    contextSelector.updateOperationContext(serverData?.user?.operationContextId, { forceChangeEvent: true });
                    return;
                }

                let eventTriggeredCount = 0;

                // Set the current context on pageshow event: https://developer.mozilla.org/en-US/docs/Web/API/Window/pageshow_event
                $d.querySelector("body").onpageshow = () => {
                    // We want to skip the very first event, which is triggered on initial page load/show

                    if (eventTriggeredCount > 1) {

                        repository.context.myPersonContext().then(data => {

                            if (data.Context_ID !== serverData.user.contextId /* Still need to test this */) {
                                contextSelector.updateContext(data.Context_ID, { name: data.CurrentContext }, true);
                            }
                        });
                    }
                    eventTriggeredCount++;
                };


                const setStartingContextNormal = () => {
                    const urlContext = serverData.context;
                    const options = { preventSaving: true };

                    if (urlContext) {
                        contextSelector.updateContext(urlContext, options);
                    } else if (!window["skip-setting-orgunit"]) {
                        contextSelector.updateContext(serverData.orgUnit.id, options);
                    }
                };

                if (!isNewTech) {
                    window.$(function () {
                        window.af.userSession.contextId = serverData.user.contextId;
                        window.af.userSession.homeOrgUnit_ID = serverData.user.contextId;
                    });
                    if (window.af?.article?.setUserContextFromDB) {
                        window.$(function () {
                            repository.context.currentContextOrgUnit().then(data => {
                                contextSelector.updateContext(data.OrgUnit_ID, {
                                    name: data.Name,
                                    idPath: data.IdPath,
                                    accessIdPath: data.AccessIdPath,
                                    preventSaving: true
                                });
                            });
                        });
                    } else {
                        window.$(setStartingContextNormal);
                    }
                } else {
                    setStartingContextNormal();
                }
            },
            privacyPolicyModal: () => {
                const $modal = $d.querySelector("#af-toolbar-pp-dlg");
                const $acceptBtn = $modal?.querySelector("#af-toolbar-accept-pp");

                if (serverData.privacyPolicyUrl) {
                    checkIfPrivacyPolicyIsApproved();
                    $acceptBtn?.addEventListener("click", acceptPrivacyPolicy);

                    if (isNewTech) {
                        $modal?.addEventListener("show.bs.modal", setIframeUrl);
                    } else {
                        window.$($modal).on("show.bs.modal", setIframeUrl);
                    }
                }

                function checkIfPrivacyPolicyIsApproved() {
                    repository.user.privacyPolicy().then(data => {
                        if (data.length === 0) {
                            // If user has not accepted privacy policy, we show the modal
                            ui.showModal($modal);
                        } else {
                            if ($acceptBtn) {
                                let formattedDate;
                                try {
                                    const privacyPolicyApprovedDate = new Date(data[0].PrivacyPolicyApproved);
                                    const day = String(privacyPolicyApprovedDate.getDate()).padStart(2, '0');
                                    const month = String(privacyPolicyApprovedDate.getMonth() + 1).padStart(2, '0');
                                    const year = privacyPolicyApprovedDate.getFullYear();
                                    formattedDate = `${day}.${month}.${year}`;
                                }
                                catch {
                                    formattedDate = '';
                                }

                                $acceptBtn.textContent = `${$t("Accepted by")} ${serverData.user.name} ${formattedDate}`;
                                $acceptBtn.setAttribute("disabled", "disabled");
                            }
                        }
                    });
                }

                function setIframeUrl() {
                    if ($modal.querySelector("#af-toolbar-pp-frame")) {
                        $modal.querySelector("#af-toolbar-pp-frame").src = serverData.privacyPolicyUrl;
                    }
                }

                function acceptPrivacyPolicy() {
                    execProc({ procName: "sstp_System_AcceptPP", data: { WebSite: serverData.alias } }).then(() => ui.hideModal($modal) /* TODO: Show toast? */);
                }
            },
            changePassword: () => {
                const $oldPassword = $d.querySelector("#old-password");
                const $newPassword = $d.querySelector("#new-password");
                const $confirmNewPassword = $d.querySelector("#confirm-password");
                const $submitNewPassword = $d.querySelector("#execute-update-password");
                const $modal = $d.querySelector("#user-new-password");
                const $passwordsNoMatchWarningLabel = $d.querySelector("#passwords-no-match");
                const $incorrectPasswordWarningLabel = $d.querySelector("#old-password-wrong");

                $submitNewPassword?.addEventListener("click", () => {
                    const newPasswordMatches = $newPassword.value === $confirmNewPassword.value;
                    if (!newPasswordMatches) {
                        $passwordsNoMatchWarningLabel.classList.remove("d-none");
                        $newPassword.value = "";
                        $confirmNewPassword.value = "";
                        return;
                    } else {
                        $passwordsNoMatchWarningLabel.classList.add("d-none");
                    }

                    _fetch("/nt/api/user/changepassword", {
                        method: "POST",
                        headers: { "Content-Type": "application/json", },
                        body: JSON.stringify({
                            newPassword: $newPassword.value,
                            oldPassword: $oldPassword.value
                        }),
                    }).then(async res => {
                        const response = await res.json();
                        if (!response.success) {
                            // Notify user that old password is wrong
                            $incorrectPasswordWarningLabel.classList.remove("d-none");
                            $oldPassword.value = "";
                        } else {
                            ui.hideModal($modal);
                        }
                    });
                });

                // Remove warning label as the user begins to type again
                $oldPassword?.addEventListener("input", () => $incorrectPasswordWarningLabel.classList.add("d-none"));

                // Reset inputs after modal closes
                const resetInputs = () => [$oldPassword, $newPassword, $confirmNewPassword].forEach($el => ($el.value = ""));
                if (isNewTech) {
                    $modal?.addEventListener("hidden.bs.modal", resetInputs);
                } else {
                    window.$($modal).on("hidden.bs.modal", resetInputs);
                }
            },
            articleHelp: async () => {
                const $helpLi = $d.querySelector("#aftoolbar-helpurl-li");
                const isLibrary = document.querySelector('meta[name="o365-libraries"]')?.content == 'true';
                if (!isLibrary) { return; }

                const HelpPages = await import('system.libraries.vue.components.HelpPages.vue').then(m => m.default);
                const HelpPagesElement = defineCustomElement(HelpPages, { shadowRoot: false });
                customElements.define('o-toolbar-helppages', HelpPagesElement);

                $helpLi.appendChild(
                    new HelpPagesElement({
                        serverData: serverData
                    })
                )
            },
            devMenu: () => {
                const $lastAppliedLabel = $d.querySelector("#dev-menu-last-updated");
                const $currentTechJobsFailingPill = $d.querySelector("#failing-jobs-warning-pill");
                const $ntJobsFailingPill = $d.querySelector("#nt-failing-jobs-warning-pill");
                const $transactionsFailedPill = $d.querySelector("#failing-transactions-warning-pill");
                const $newTechJobsHref = $d.querySelector("#new-tech-jobs-link");
                const $siteNotificationMessageLabel = $d.querySelector("#site-notification-message");
                const $feedbackBtn = $d.querySelector("#af-navbar-user-feedback");
                const $warningCont = $d.querySelector("#setup-warning-container");
                const $devMenuButton = $d.querySelector("#button-megamenu");

                function loadDevMenuData() {
                    // dsTransactionsFailed
                    repository.devMenu.transactionsFailing().then(data => {
                        const numFailedTransactions = data?.ID || 0;
                        if (numFailedTransactions > 0) {
                            ui.show($transactionsFailedPill);
                            $transactionsFailedPill && ($transactionsFailedPill.textContent = numFailedTransactions);
                        } else {
                            ui.hide($transactionsFailedPill);
                        }
                    });

                    // dsTFJobsFailing
                    repository.devMenu.jobsFailing().then(data => {
                        const numFailingJobs = data?.ID || 0;
                        setPillContent($currentTechJobsFailingPill, numFailingJobs);
                    });

                    repository.devMenu.ntJobsFailing().then(data => {
                        const numFailingJobs = data?.ID || 0;
                        setPillContent($ntJobsFailingPill, numFailingJobs);
                    });

                    repository.devMenu.lastApplied().then(data => $lastAppliedLabel && ($lastAppliedLabel.textContent = new Date(data.Applied)?.toLocaleString()));

                    loadSourceUrls();
                }

                function loadDbSetupInformation() {
                    // DB Setup info
                    repository.devMenu.databaseSetup().then(data => {
                        if (!data) {
                            return;
                        }

                        if ($newTechJobsHref && serverData.isDeveloper && data.NTJobsUrl) {
                            $newTechJobsHref.href = data.NTJobsUrl;
                        }

                        if ($feedbackBtn) {
                            if (data.FeedbackUrl) {
                                repository.context.myPersonContext().then(response => {
                                    if (response.EnableFeedback) {
                                        $feedbackBtn.parentElement.parentElement.classList.remove("d-none");

                                        let referrer = "";
                                        try {
                                            referrer = encodeURIComponent(location.href);
                                        } catch {
                                            console.error("Failed to encodeUriComponent location.href: ", location.href);
                                        }

                                        let feedbackUrl = data.FeedbackUrl ?? "https://omega.omega365.com/nt/feedback";
                                        if (referrer && new URL(feedbackUrl).searchParams.size > 0) {
                                            feedbackUrl += `&referrer=${referrer}`;
                                        } else if (referrer) {
                                            feedbackUrl += `?referrer=${referrer}`;
                                        }

                                        $feedbackBtn.parentElement.href = feedbackUrl;
                                    }
                                });
                            }
                        }

                        if ($warningCont){
                            if(data.Warning){
                                $warningCont.parentElement.classList.remove('d-none');
                                $warningCont.textContent = data.Warning;
                                $warningCont.title = data.WarningTitle ?? data.Warning;
                            }
                        }

                        if ($siteNotificationMessageLabel && data.ToolbarNotificationMessage) {
                            $siteNotificationMessageLabel.textContent = `! ${data.ToolbarNotificationMessage}`;
                            ui.show($siteNotificationMessageLabel.parentElement);
                        }
                    });
                }

                function setPillContent($pill, count) {
                    if (count > 0 && $pill) {
                        ui.show($pill);
                        $pill.textContent = count;
                    } else {
                        ui.hide($pill)
                    }
                }

                async function loadSourceUrls() {
                    const data = await repository.devMenu.articleSourceUrl();

                    const url = data?.OriginUrl;
                    if (!url) {
                        return;
                    }

                    try {
                        $d.querySelectorAll(".js-dev-menu-source-url").forEach($el => {
                            if (!$el.href) {
                                return;
                            }
                            const urlObject = new URL($el.href);
                            urlObject.hostname = new URL(url).hostname;
                            $el.href = urlObject.toString();
                        });
                    } catch (ex) {
                        console.error(ex);
                    }
                }

                function attachDomEventHandlers() {
                    if ($devMenuButton && serverData.isDeveloper) {
                        $devMenuButton.addEventListener("click", loadDevMenuData);
                    }
                }

                attachDomEventHandlers();
                loadDbSetupInformation();
            },
            contextSelectorButton() {
                // assign event to window object to call hideDialog function from OrgUnitChooser component
                window.addEventListener("message", (event) => {
                    if (event.data === 'hideChooser') {
                        hideDialog();
                    }
                });

                const $triggerBtn = $d.querySelector("#user-context-selector-button");
                const events = { "onClosed": [] };
                let $dialog = null;

                const desktopMarkup = `
                <div id="af-org-unit-chooser" style="display:none;" class="bg-white orgunit-chooser">
                   <iframe id="af-org-unit-chooser-iframe" src="about:blank" style="position: fixed; top: 58px; right: 12px;  background: whitesmoke; min-width: 735px; min-height:300px; height: 600px; max-height: calc(100vh - 70px); border: 1px solid lightgray; z-index: 100000; resize:both;box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;"></iframe>
                </div>',           
                `;

                const mobileMarkup = `
                <div id="af-org-unit-chooser-mobile" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: whitesmoke; border: 1px solid lightgray; z-index: 100000; display:none; box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;" class="bg-white">
                    <iframe id="af-org-unit-chooser-iframe" class="w-100 h-100 border-0" src="about:blank"></iframe>
                </div>
                `;

                function init() {
                    if ($dialog === null) {
                        setTimeout(() => {
                            window["afOrgUnitChooserToolbar"] = { element, setHeight, close };
                        }, 0);

                        // Create DOM element from markup
                        const placeholder = document.createElement("div");
                        if (isMobile) {
                            placeholder.innerHTML = mobileMarkup;
                        } else {
                            placeholder.innerHTML = desktopMarkup;
                        }
                        const node = placeholder.firstElementChild;
                        $dialog = node;

                        // Insert it into body
                        $d.querySelector("body").append($dialog);

                        attachDomEventHandlers();
                    }
                }

                function attachDomEventHandlers() {
                    const $iframe = $dialog.querySelector("iframe");

                    // Add click event to modal button
                    $dialog.querySelector("button")?.addEventListener("click", close);

                    // Close if user clicks outside of dropdown
                    $d.addEventListener("click", (evt) => {
                        if (!evt.target.closest(".orgunit-chooser")
                            && !evt.target.closest(".toolbar-context-title")
                            && elemIsVisible($dialog)
                            && evt.target != $dialog
                        ) {
                            close();
                        }
                    });

                    $triggerBtn?.addEventListener("click", (evt) => {
                        evt.stopPropagation();
                        if (!elemIsVisible($dialog)) {
                            if ($iframe?.src === "about:blank") {
                                $iframe.src = makeContextSelectorIFrameSource();
                            } else {
                                if ($iframe.contentWindow.showAll) {
                                    $iframe?.contentWindow?.showAll(); // showAll() is a function in 'orgunit-chooser-toolbar' article
                                }
                            }
                            showDialog();
                        } else {
                            hideDialog();
                        }
                    });

                    $iframe?.addEventListener("load", () => {
                        const $closeBtn = $iframe?.contentWindow?.document?.querySelector("#af-orgunit-close"); // Close button, visible on mobile

                        if ($closeBtn) {
                            $closeBtn.addEventListener("click", close);
                        }
                    });
                }

                //TODO: Do we need this?
                function fireEvent(eventName, data) {
                    events[eventName]?.forEach(handlerFunc => handlerFunc(data));
                }

                //TODO: Do we need this?
                function attachEvent(eventName, handlerFunc) {
                    if (!events.hasOwnProperty(eventName)) {
                        throw new Error(`The event '${eventName}' does not exist`);
                    }
                    events[eventName].push(handlerFunc);
                }

                //TODO: Do we need this?
                function detachEvent(eventName, handlerFunc) {
                    if (!events.hasOwnProperty(eventName)) {
                        throw new Error(`The event '${eventName}' does not exist`);
                    }
                    events[eventName] = events[eventName].filter(f => f !== handlerFunc);
                }

                // Copied from jQuery source code. Use to get equivalent to '.is(":visible")'
                // https://github.com/jquery/jquery/blob/main/src/css/hiddenVisibleSelectors.js
                function elemIsVisible($elem) {
                    return !!($elem.offsetWidth || $elem.offsetHeight || $elem.getClientRects().length);
                }

                function showDialog() {
                    $dialog.style.display = "";
                }

                function hideDialog() {
                    $dialog.style.display = "none";
                }

                // NOTE: Required in the 'orgunit-chooser-toolbar' article. This is the function that updates current context after user selects it from Org unit selector.
                window["tbOrgunitLkpSelected"] = (row) => {
                    contextSelector.updateContext(row.ID, {
                        name: row.Name,
                        idPath: row.IdPath,
                        code: row.Code,
                        accessIdPath: row.AccessIdPath
                    }, true);

                    close();

                    if (!isNewTech) {
                        af.userSession.homeOrgUnit_ID = row.ID;
                    }
                };

                // NOTE: Required in the 'orgunit-chooser-toolbar' article
                function element() {
                    return null;
                    // return $dialog;
                }

                // NOTE: Required in the 'orgunit-chooser-toolbar' article
                function setHeight(pHeight) {
                    return;
                    // if (isMobile()) {
                    //     return;
                    // }

                    // $dialog.style.height = `${pHeight + 110}px`;
                }

                // NOTE: Required in the 'orgunit-chooser-toolbar' article
                function close() {
                    hideDialog();
                    fireEvent("onClosed", {});
                }

                init();
            },
            feedback() {
                /*const feedbackBtn = $d.querySelector("#af-navbar-user-feedback");
                if (feedbackBtn) {
                    feedbackBtn.addEventListener("click", async function () {
                        const { createFeedbackService } = await import('scope.libraries.vue.components.FeedbackService.ts');
                        createFeedbackService();
                    });
                }*/
            },
            contextLabels: () => {
                let updateHomeContext = true;

                const $$mobileNavLabel = $d.querySelectorAll(".home-context-name-pwa");
                const $$homeContextName = $d.querySelectorAll(".home-context-name");
                const $$homeContextNameLg = $d.querySelectorAll(".home-context-name-lg");
                const $$contextName = $d.querySelectorAll(".context-name");
                const $$contextNameLg = $d.querySelectorAll(".context-name-lg");
                const $$contextMenuSelectedHome = $d.querySelectorAll("#context-menu .dropdown-usercontext span");

                const setTextContent = (label) => ($el) => $el && ($el.textContent = label);

                function updateTextLabels(ctx) {
                    $$mobileNavLabel.forEach(setTextContent(ctx.OrgUnitName));

                    if (!ctx.preventSaving) {
                        [...$$contextName, ...$$contextNameLg].forEach(setTextContent(ctx.OrgUnitName));
                    }
                    if (ctx.TriggeredByUser) {
                        $$contextMenuSelectedHome.forEach(setTextContent(ctx.OrgUnit || ctx.name));

                        if (updateHomeContext) {
                            $$homeContextName.forEach(setTextContent(ctx.OrgUnitName));
                            $$homeContextNameLg.forEach(setTextContent(ctx.OrgUnit || ctx.name));
                        }
                    }
                }

                function onNavigateToSet() {
                    updateHomeContext = false;
                }

                // Attach contextSelector event handler so that we can update text labels whenever context changes
                contextSelector.attachEvent("onContextChanged", updateTextLabels);
                contextSelector.attachEvent("onNavigateToSet", onNavigateToSet);
            },
            contextMenu: () => {
                // Logic for handling events related to the Context Menu
                const $domainItem = $d.querySelector("#context-menu .dropdown-domain");
                const $recordItem = $d.querySelector("#context-menu .dropdown-orgunit");
                const $userContextItem = $d.querySelector("#context-menu .dropdown-usercontext");
                const $$items = [$domainItem, $recordItem, $userContextItem];
                const $$parentArticleLabels = $d.querySelectorAll("#context-menu .parent-article-title");
                const $dropdownContainer = $d.querySelector("#context-selector-dropdown-container");
                const attributeName = "data-context-orgunit-id";

                function init() {
                    maybeHideParentArticleLabels();
                    attachEventListeners();
                }

                function maybeHideParentArticleLabels() {
                    if (!serverData.parentArticleTitle) {
                        $$parentArticleLabels.forEach(ui.hide);
                    }
                }

                function attachEventListeners() {
                    contextSelector.attachEvent("onContextChanged", updateHomeContextHref);
                    contextSelector.attachEvent("onContextChanged", checkForDuplicates);
                    contextSelector.attachEvent("onNavigateToSet", setDataAttributes);
                    contextSelector.attachEvent("onNavigateToSet", checkForDuplicates);
                    contextSelector.attachEvent("onNavigateToSet", maybeAddRegisterParamsToUrls);
                    $dropdownContainer?.addEventListener("click", handleDropdownContainerClicked);
                }

                function handleDropdownContainerClicked(evt) {
                    if (!serverData.parentArticleId) {
                        return;
                    }

                    // Links are hidden in the context dropdown if they are duplicates of other links, therefore we can use this fact to find out how many unique links there are in dropdown.
                    const uniqueLinksInDropdown = $$items.filter($i => !$i.classList.contains("d-none"));
                    if (uniqueLinksInDropdown.length === 1) {
                        // If there is only one link in the dropdown, we should navigate to that link immediately (WF: 990848).                        
                        const url = uniqueLinksInDropdown[0].href;
                        if (url) {
                            $d.querySelector("#context-menu")?.remove(); // Remove the dropdown menu from DOM
                            window.location = url;
                        }
                    }
                }

                function updateHomeContextHref() {
                    if (!$userContextItem) {
                        return;
                    }
                    try {
                        const homeContext = contextSelector.getHomeContext();
                        const url = new URL($userContextItem.href);
                        url.searchParams.set("Context", homeContext);
                        $userContextItem.href = url.toString();
                    } catch (ex) {
                        console.error("Failed to set home context dropdown href",);
                    }
                }

                // Update data-context-orgunit-id attributes on the menu items
                function setDataAttributes(obj) {
                    if (obj?.domainOrgUnit_ID) {
                        $domainItem?.setAttribute(attributeName, obj.domainOrgUnit_ID);
                    } else {
                        $domainItem?.removeAttribute(attributeName);
                    }

                    if (obj?.orgUnit_ID) {
                        $recordItem?.setAttribute(attributeName, obj.orgUnit_ID);
                    } else {
                        $recordItem?.removeAttribute(attributeName);
                    }
                }

                // We want to remove duplicate links in the Context Menu, so on certain events we check the DOM to see if there are any duplicates.
                function checkForDuplicates() {
                    const contexts = [$userContextItem?.getAttribute(attributeName), $domainItem?.getAttribute(attributeName), $recordItem?.getAttribute(attributeName)]
                        .filter(x => x)
                        .map(x => +x);
                    const uniqueContexts = new Set(contexts);

                    if (uniqueContexts.size === 1) {
                        // If all menu items have the same context, only show one of them ($userContextItem).
                        [$domainItem, $recordItem].forEach($el => $el?.classList?.add("d-none"));
                        $userContextItem?.classList?.remove("d-none");
                    } else {
                        // Otherwise, we filter the ones that are unique
                        uniqueContexts.forEach(contextId => {
                            // Get the context items with matching ID and only show the first one, hiding any other context items with the same context ID
                            $$items.filter($el => $el && $el.getAttribute(attributeName) == contextId).forEach(($el, i) => {
                                if (i === 0) {
                                    $el.classList.remove("d-none");
                                } else {
                                    $el.classList.add("d-none");
                                }
                            });
                        });
                    }

                    reOrderItemNumbers();
                }

                // Reset numbers on visible context menu items
                function reOrderItemNumbers() {
                    $$items.filter($el => $el && !$el.classList.contains("d-none")).forEach(($el, i) => {
                        const $spanEl = $el.querySelector("span");
                        if ($spanEl) {
                            $spanEl.textContent = $spanEl.textContent?.replace(/[0-9]{1,}\)/, `${i + 1})`);
                        }
                    });
                }

                function maybeAddRegisterParamsToUrls(opts) {
                    if (opts.registerId) {
                        $$items.forEach($el => {
                            try {
                                const url = new URL($el.href); // Parse current href attribute
                                url.searchParams.set("Register_ID", opts.registerId); // Add Register_ID query param
                                $el.href = url.toString(); // Update element href to new URL                                
                            } catch (ex) {
                                console.error(`Failed to set context dropdown register params:`, ex, $el);
                            }
                        });
                    }
                }

                init();
            },
            notifications: () => {
                const $unreadNotificationsIcon = $d.querySelector("#unread-notifications-icon");
                const $modal = $d.querySelector("#af-navbar-notifications-modal");
                const $triggerButton = $d.querySelector("#button-notify-messages");
                const $iframe = $modal?.querySelector("iframe");
                const notificationsUrl = "/nt/notifications?HideNav=true";

                function checkUnreadNotificationsBell() {
                    // dsTFUnreadItems
                    repository.notifications.hasUnreadNotifications()
                        .then(data => data?.Unread ? ui.addClass($unreadNotificationsIcon, "af-navbar-unread-notif-icon") : ui.removeClass($unreadNotificationsIcon, "af-navbar-unread-notif-icon"));
                }

                // Logic for closing the notifications modal, which due to the fact that it has data-backdrop="false" attribute, will not autoclose when clicking outside of it. Maybe convert it to a dropdown instead?
                $d.addEventListener("click", (evt) => {
                    const eventTriggerIsModalOpener = (evt.target.hasAttribute("href") && evt.target.getAttribute("href") === $modal?.id) || evt.target.closest(`[href='#${$modal?.id}']`);
                    const clickedOutsideModalBody = evt.target.id === $modal?.id;

                    if (clickedOutsideModalBody && !eventTriggerIsModalOpener) {
                        if (isNewTech) {
                            window.bootstrap.Modal.getOrCreateInstance("#" + $modal?.id).hide();
                        } else {
                            window.$($modal).modal("hide");
                        }
                    }
                });

                // Lazy load iframe content
                $triggerButton?.addEventListener("mouseenter", lazyLoadFrameContent);
                $triggerButton?.addEventListener("click", lazyLoadFrameContent); // Fallback in case mouseenter events are not triggered (touch screens)

                // If we are on home page, we preload the notifications iframe contents.
                if (__navBarHelpers.isOnHomePage() && $iframe) {
                    $iframe.src = notificationsUrl;
                }

                function lazyLoadFrameContent() {
                    if ($iframe && !$iframe.src) {
                        $iframe.src = notificationsUrl;
                    }
                }

                // When the notifications modal is opened, we need to trigger the sstp_Notify_ItemsRead proc.
                // Since we are using an iframe, we have to wait until the contents of the iframe has loaded, and then use a callback proc that the notifications article has exposed.
                const triggerItemsReadProc = () => {
                    const interval = setInterval(async () => {
                        if ($iframe.contentWindow["__onNotificationsModalOpened"]) {
                            clearInterval(interval);
                            await $iframe.contentWindow["__onNotificationsModalOpened"]();
                            checkUnreadNotificationsBell();
                        }
                    }, 100);
                };

                if (isNewTech || isBootstrap5) {
                    $modal?.addEventListener("show.bs.modal", triggerItemsReadProc);
                } else {
                    window.$($modal).on("show.bs.modal", triggerItemsReadProc);
                }

                checkUnreadNotificationsBell();
                addIFrameLinkHandler($iframe, { alwaysOpenInNewTab: true });
            },
            megaMenu: () => {
                const $modal = $d.querySelector("#mega-menu");
                const $iframe = $modal?.querySelector("iframe");
                const $megaMenuOpenBtn = $d.querySelector('[href="#mega-menu"]');
                const urlParams = new URL(window.location).searchParams;
                const megaMenuUrl = () => `/nt/menu?HideNav=true&Context=${contextSelector.getSessionContext()}`;

                if (isNewTech) {
                    $modal?.addEventListener("shown.bs.modal", focusSearchInput);
                    $modal?.addEventListener("hidden.bs.modal", resetSearchInput);
                } else {
                    window.$($modal).on("shown.bs.modal", focusSearchInput);
                    window.$($modal).on("hidden.bs.modal", resetSearchInput);
                }

                if (__navBarHelpers.isOnHomePage() && $iframe) {
                    // If we are on home page, we preload mega menu
                    //$iframe.src = megaMenuUrl();
                }

                // Sometimes the user opens the modal before the iframe has finished loading. We want to set focus on search element as the iframe finishes loading.
                $iframe?.addEventListener("load", () => {
                    if ($modal.classList.contains("show")) {
                        focusSearchInput();
                    }
                });

                // Open modal on CTRL + M key combination
                $d.addEventListener("keydown", evt => {
                    if (evt.code === "KeyM" && evt.ctrlKey) {
                        evt.preventDefault();
                        lazyLoadFrameContent();
                        openMegaMenu();
                    }
                });

                if (!serverData.hideNav) {
                    //$megaMenuOpenBtn?.addEventListener("mouseenter", lazyLoadFrameContent);
                    $megaMenuOpenBtn?.addEventListener("click", lazyLoadFrameContent); // Fallback in case mouseenter events are not triggered (touch screens)
                }

                function lazyLoadFrameContent() {
                    if ($iframe && !$iframe.src) {
                        $iframe.src = megaMenuUrl();
                    }
                }

                function focusSearchInput() {
                    callMegaMenuCallbackFunc("__afMegaMenuFocusInputElementCallback");
                }

                function resetSearchInput() {
                    callMegaMenuCallbackFunc("__afMegaMenuResetSearchValueCallback");
                }

                function callMegaMenuCallbackFunc(funcName) {
                    if ($iframe?.contentWindow[funcName]) {
                        $iframe.contentWindow[funcName]();
                    }
                }

                function closeMegaMenu() {
                    if (isNewTech) {
                        window.bootstrap.Modal.getOrCreateInstance("#" + $modal.id).hide();
                    } else {
                        window.$($modal).modal("hide");
                    }
                }

                function openMegaMenu() {
                    if (isNewTech) {
                        window.bootstrap.Modal.getOrCreateInstance("#" + $modal.id).show();
                    } else {
                        window.$($modal).modal("show");
                    }
                }

                // Add a callback function to the window scope so that the iframe can call it
                window["__afMegaMenuCloseCallback"] = () => closeMegaMenu();
                addIFrameLinkHandler($iframe);
            },
            bookmarkCurrentUrl: () => {
                const $modal = $d.querySelector("#af-bookmark-url-modal");
                const $nameInput = $modal?.querySelector("#af-bookmark-name");
                const $submitBtn = $modal?.querySelector("#save-bookmark-btn");

                // Reset input field when modal is closed
                const resetInputField = () => ($nameInput.value = "");
                if (isNewTech) {
                    $modal?.addEventListener("hidden.bs.modal", resetInputField);
                } else {
                    window.$($modal).on("hidden.bs.modal", resetInputField);
                }

                $submitBtn?.addEventListener("click", async () => {
                    const name = $nameInput.value;
                    const qStr = window.location.search.substring(1);

                    await execProc({
                        procName: "sstp_System_BookmarksAdd",
                        data: {
                            ArticleID: serverData.articleId,
                            HostName: serverData.hostName,
                            QueryString: qStr,
                            Name: name,
                            IsNTApp: isNewTech
                        }
                    });

                    if (isNewTech || isBootstrap5) {
                        window.bootstrap.Modal.getOrCreateInstance("#" + $modal.id).hide();
                    } else {
                        window.$($modal).modal("hide");
                    }
                });
            },
            deleteUserAccount: () => {
                const $btn = $d.querySelector("#initiate-account-deletion");

                $btn?.addEventListener("click", deleteAccount);

                function deleteAccount() {
                    execProc({
                        procName: "sstp_System_AccessRequests_Create",
                        data: {
                            RequestedBy_ID: serverData.user.id,
                            AccessForPerson_ID: serverData.user.id,
                            DescriptionOfRequiredAccess: serverData.user.name + " has requested their account to be deleted.",
                            OrgUnit_ID: serverData.user.orgUnitId,
                            AccountToBeDeleted: true
                        }
                    });
                }
            },
            myNotes: () => {
                const $btn = $d.querySelector("#nav-my-notes-btn");

                function init() {
                    $btn?.addEventListener("click", launchMyNotes);
                    loadUserEmail();
                }

                async function loadUserEmail() {
                    let user = null;

                    if (isNewTech) {
                        try {
                            await import('o365-modules').then(x => {
                                user = { Email: x.userSession.emailAddress };
                            });
                        } catch (_) {
                            user = await repository.user.getEmail();
                        }
                    } else {
                        user = await repository.user.getEmail();
                    }

                    // Only show My Notes button for Omega365 employees
                    if (user && user.Email && user.Email.trim().endsWith("@omega365.com")) {
                        ui.show($btn);
                    }
                }

                function launchMyNotes() {
                    import('o365.controls.NotesApp.ts').then(x => x.default());
                }


                if (isNewTech) {
                    init();
                }
            },
            clearStorage: () => {
                const $btn = $d.querySelector("#nav-clear-storage");

                function init() {
                    $btn?.addEventListener("click", clearAppStorage);
                }

                function clearAppStorage() {
                    import('o365.modules.StorageHelpers.ts').then(x => { x.default.clear(); location.reload(); });
                }

                if (isNewTech) {
                    init();
                }
            },
            toggleSpellcheck: () => {
                const $btn = $d.querySelector("#nav-toggle-spellcheck");

                function init() {                    
                    if ($btn) {
                        $btn.addEventListener("click", toggle);
                        updateButtonLabel();
                    }                    
                }

                function toggle() {
                    import('o365.modules.StorageHelpers.ts').then(x => {
                        const value = x.default.getItem("o365-spellcheck", { global: true }, "false");
                        const enabled = isEnabled(value);
                        x.default.setItem("o365-spellcheck", !enabled, { global: true });
                        const event = new CustomEvent("o365-spellchecktoggle");
                        document.body.dispatchEvent(event);
                        try {
                            document.querySelectorAll('iframe').forEach(x => {
                                x.contentWindow.document.body.dispatchEvent(event);
                            });
                            updateButtonLabel();
                        } catch (ex) {
                            console.log(ex);
                        }
                    });
                }

                function updateButtonLabel() {
                    import('o365.modules.StorageHelpers.ts').then(x => { 
                        const value = x.default.getItem("o365-spellcheck", { global: true }, "false");                        
                        if (isEnabled(value)) {
                            $btn.textContent = $t("Disable Spell Checking");
                        } else {
                            $btn.textContent = $t("Enable Spell Checking");
                        }
                    });                    
                }

                function isEnabled(value) {
                    return value === 'true' || value === '';;
                }

                if (isNewTech) {
                    init();
                }
            },
            relatedArticles: () => {
                if (isNewTech) {

                    return; // Not enabled on NT
                }

                const $icon = $d.querySelector("#related-articles-container");
                const $listContainer = $icon?.querySelector("#af-related-menu");

                if (!$icon || !$listContainer) {
                    console.warn("Could not find related articles DOM nodes");
                    return;
                }

                async function init() {
                    repository.relatedPages.getRelatedPages()
                        .then(renderRelatedArticles)
                        .catch(e => console.error("Failed to load Related Articles data", e));

                    // Object exposed to the window scope for updating query strings in related articles.
                    window["relatedArticles"] = { setQueryString };
                }

                function setQueryString(queryStr) {
                    $listContainer.querySelectorAll("a").forEach($el => {
                        const newHref = $el.href.substring(0, $el.href.indexOf("?") > -1 ? $el.href.indexOf("?") : $el.href.length) + "?" + queryStr;
                        $el.href = newHref;
                    });
                }

                function renderRelatedArticles(data) {
                    if (!data || data?.length === 0) {
                        return;
                    }

                    $listContainer.innerHTML = ""; // Clear container first, in case of re-render.
                    data.forEach(createListElement);
                    ui.show($icon);
                }

                function createListElement(article) {
                    const queryString = article.QueryString;
                    const $el = $d.createElement("a");
                    $el.href = `/${article.ArticleID}${queryString ? `?${queryString}` : ''}`;
                    $el.classList.add("dropdown-item");
                    $el.innerText = article.ArticleTitle;
                    $listContainer.appendChild($el);
                }

                init();
            },
            operationMode: () => {
                if (!isInOperationMode()) {
                    return;
                }

                const $contextSelectorContainer = $d.querySelector("#op-context-selector-container");
                const $contextDropdownContainer = $contextSelectorContainer.querySelector("#op-context-dropdown-container");
                const $currentOpContexLabel = $contextSelectorContainer.querySelector("#current-op-context-label");
                const $loadingPlaceholder = $d.querySelector("#op-context-loading-placeholder");

                async function init() {
                    await doInitialDataLoad();
                }

                function setCurrentOpContext(orgUnitId) {
                    return () => {
                        if (!isNaN(orgUnitId)) {
                            contextSelector.updateOperationContext(orgUnitId).then(loadMyCurrentOpContext);
                        }
                    }
                }

                async function loadMyCurrentOpContext() {
                    const data = await repository.operationContext.myCurrentOperationContext();
                    $currentOpContexLabel.textContent = data?.OrgUnitName || $t("No Operation Context Set");
                }

                async function loadOrgUnitDropdownData() {
                    const data = await repository.operationContext.myOrgUnitRoles();
                    $contextDropdownContainer.innerHTML = ""; // Remove children
                    data.forEach(createContextDropdownListElement);
                    if ($contextDropdownContainer.children.length === 0) {
                        createEmptyContextDropdownElement();
                    }
                }

                function createContextDropdownListElement(record) {
                    const $el = $d.createElement("li");
                    $el.classList.add("dropdown-item");
                    $el.style.cursor = "pointer";
                    $el.onclick = setCurrentOpContext(record.OrgUnit_ID);
                    $el.textContent = record.OrgUnit;
                    $contextDropdownContainer.appendChild($el);
                }

                async function doInitialDataLoad() {
                    loadMyCurrentOpContext().then(() => {
                        ui.hide($loadingPlaceholder);
                        ui.show($contextSelectorContainer);
                    });
                    loadOrgUnitDropdownData();
                }


                function createEmptyContextDropdownElement() {
                    const $el = $d.createElement("li");
                    $el.classList.add("dropdown-item");
                    $el.textContent = $t("You have no Operation Org Unit Roles");
                    $contextDropdownContainer.appendChild($el);
                }

                init();
            },
        }
    }

    return { initNavBar, setContextSelectorOptions };
})();

// CT "exports"
if (!__navBarHelpers.isNewTech()) {
    window["__dataAccess"] = __dataAccess;
    window["__navBarHelpers"] = __navBarHelpers;
    window["orgunit_lookup"] = orgunit_lookup;
}

function _fetch(resource, options) {
    options.headers["X-O365-Context-Id"] = +contextSelector.getSessionContext()
    if (!document.querySelector('meta[name="o365-person-id"]')) {
        return fetch(resource, options);
    }
    if (resource.startsWith("/api/apps/data")) {
        resource = resource.replace("/api/apps/data", "/api/data")
    }

    return fetch(resource, options);
}

window["contextSelector"] = contextSelector; // This needs to be available on both CT and NT since the org unit selector in NavBar uses it.
contextSelector["setContextSelectorOptions"] = setContextSelectorOptions;

// NT exports
export { initNavBar, contextSelector };
